import * as S from './styles'
import { Dropdown, MenuProps, Space } from 'antd'
import { useEffect, useState } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import LogoLoja from '../../assets/images/LogoLoja.png'
import { Cores } from '../../shared/utils/styles'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router'

type HeaderTypes = {
  titulo: string
}

export const Header = ({titulo}: HeaderTypes) => {
  const navigate = useNavigate()

  useEffect(() => {
    console.log(window.location.search.includes('Carro%2FCamionetas'))
  },[])

  const [header, setHeader] = useState("header-video")
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPercentage = getScrollPercentage();

      if (scrollPercentage > 70) { // Altere para o percentual desejado
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const getScrollPercentage = () => {
    const scrollTop = window.scrollY;
    const docHeight = document.documentElement.scrollHeight - window.innerHeight;
    return (scrollTop / docHeight) * 100;
  };
  const itemsEstoque: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div onClick={() => {
          navigate("/estoque?categoria=Carro%2FCamionetas")
        }}>
          {window.location.search.includes('Carro%2FCamionetas') ? (
            <span><b>VEÍCULOS</b></span>
            ): (
              <span>VEÍCULOS</span>
          )}
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div onClick={() => {
          navigate("/estoque?categoria=Náutica")
        }}>
          {window.location.search.includes('Náutica') ? (
            <span><b>NÁUTICA</b></span>
            ): (
              <span>NÁUTICA</span>
          )}
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div onClick={() => {
          navigate("/estoque?categoria=Moto")
        }}>
          {window.location.search.includes('Moto') ? (
            <span><b>MOTO</b></span>
            ): (
              <span>MOTO</span>
          )}
        </div>
      ),
    }
  ];
  
    const items: MenuProps['items'] = [
      {
        key: '0',
        label: (
          <a rel="noopener noreferrer" href="/">
            HOME
          </a>
        ),
      },
      {
        key: '1',
        label: (
          <a rel="noopener noreferrer" href="/sobre-nos">
            QUEM SOMOS
          </a>
        ),
      },
      {
        key: '2',
        label: (
          <>
            ESTOQUE
          </>
        ),
        children: [
          {
            key: '1-1',
            label: (
              <div onClick={() => {
                navigate("/estoque?categoria=Carro%2FCamionetas")
              }}>
              {window.location.search.includes('Carro%2FCamionetas') ? (
                <span><b>VEÍCULOS</b></span>
                ): (
                  <span>VEÍCULOS</span>
              )}
              </div>
              
            ),
          },
          {
            key: '1-2',
            label: (
              <div onClick={() => {
                navigate("/estoque?categoria=Náutica")
              }}>
              {window.location.search.includes('Náutica') ? (
                <span><b>NÁUTICA</b></span>
                ): (
                  <span>NÁUTICA</span>
              )}
              </div>
            ),
          },
          {
            key: '1-3',
            label: (
              <div onClick={() => {
                navigate("/estoque?categoria=Moto")
              }}>
              {window.location.search.includes('Moto') ? (
                <span><b>MOTO</b></span>
                ): (
                  <span>MOTO</span>
              )}
              </div>
            ),
          },
        ],
      },
      {
        key: '3',
        label: (
          <a rel="noopener noreferrer" href="/avaliacao">
            AVALIAÇÃO
          </a>
        ),
      },
      {
        key: '4',
        label: (
          <a rel="noopener noreferrer" href="/contato">
            CONTATO
          </a>
        ),
      },
      {
        key: '5',
        label: (
          <a rel="noopener noreferrer" href="/pos-venda">
            PÓS-VENDA
          </a>
        ),
      },
    ];

    const listenScrollEvent = () => {
      if (window.scrollY < 50) {
        return setHeader("header-video")
      } else if (window.scrollY > 50) {
        return setHeader("header-default")
      }
    }
  
    useEffect(() => {
      window.addEventListener('scroll', listenScrollEvent);
  
      return () =>
        window.removeEventListener('scroll', listenScrollEvent);
    }, []);
    return (
      <S.Container>
            {header !== 'header-video' && !isMobile ?  (
      <S.Opcoes>
      <div className={header}>
          <div className='options-header-default'>
            <a className='header-default-links' href="/">Home</a>
            <a className='header-default-links' href="/sobre-nos">Quem somos</a>
            <Dropdown className='dropdown-default' menu={{ items: itemsEstoque }}>
                <a href='/' onClick={(e) => e.preventDefault()}>
                <Space>
                    Estoque
                </Space>
                </a>
            </Dropdown>
            <a className='header-default-links' href="/avaliacao">Avaliação</a>
            <a className='header-default-links' href="/contato">Contato</a>
            <a className='header-default-links' href="/pos-venda">Pós-venda</a>
          </div>
      </div>
      </S.Opcoes>
        ) : (
        <S.Opcoes>
        <div className={header} style={scrolled && isMobile && (window.location.pathname === '/' || window.location.pathname === '/sobre-nos') ? {position: 'unset'} : {position: 'fixed'}}>
            <Dropdown className='dropdown' menu={{ items }}>
                <a href='/' onClick={(e) => e.preventDefault()}>
                <Space>
                    <p>{titulo}</p>
                    <AiOutlineMenu size={30} color={Cores.BRANCO} />
                </Space>
                </a>
            </Dropdown>
        </div>
        </S.Opcoes>
        )}
    </S.Container>
    )
}