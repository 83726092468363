//================================================
// Envios para o back end
export enum EnvioEmailContato {
  MENSAGEM = 'mensagem',
  NOME = 'nome',
  TELEFONE = 'telefone',
  EMAIL = 'email',
  EMAIL_LOJA = 'emailLoja',
  CIDADE = 'cidade',
}

export enum Funcionario {
  NOME = 'nome',
  ID = 'id',
  FUNCIONARIO = 'funcionario',
  SETOR = 'setor',
  DESCRICAO = 'descricao',
  SITUACAO = 'situacao',
  ARQUIVO = 'arquivo',
}

export enum EnvioEmailPosVenda {
  CLIENTE = 'cliente',
  CONTATO = 'contato',
  DATA_COMPRA = 'dataCompra',
  PLACA = 'placa',
  PROBLEMAS = 'problemas',
  VEICULO = 'veiculo',
  EMAIL = 'email',
  VENDEDOR = 'vendedor',
  EMAIL_LOJA = 'emailLoja',
}

export enum EnvioEmailAvaliacao {
  NOME = 'nome',
  EMAIL = 'email',
  CELULAR = 'celular',
  MOTIVO = 'motivo',
  MARCA = 'marca',
  MODELO = 'modelo',
  ANO_FABRICACAO = 'ano_fabricacao',
  ANO_MODELO = 'ano_modelo',
  COR = 'cor',
  COMBUSTIVEL = 'combustivel',
  EMAIL_LOJA = 'emailLoja',
}

export enum EnvioEmailProposta {
  CIDADE = 'cidade',
  EMAIL = 'email',
  NOME = 'nome',
  TELEFONE = 'telefone',
  UF = 'uf',
  MENSAGEM = 'mensagem',
  VEICULO = 'veiculo',
  EMAIL_LOJA = 'emailLoja',
}

//================================================
// Retornos do back end
export enum Loja {
  CODIGO_LOJA = "cod_loja",
  NOME_EMPRESA = "nome_empresa",
  TELEFONES = "telefones",
  EMAILS = "emails",
  ENDERECOS = "enderecos"
}
export enum InfosLoja {
  INFO = "loja",
  COD_LOJA = "id",
  DESCRICAO = "descricao",
  URL_INSTAGRAM = "urlInstagram",
  URL_FACEBOOK = "urlFacebook",
  HORAIRO_SEG_SEX = "horarioSegSex",
  HORAIRO_SAB = "horarioSab",
  HORAIRO_DOM = "horarioDomingo",
}

export enum Banner {
  BANNER = 'banner',
  VIDEO = 'video',
  ID = 'id',
  COD_LOJA = 'lojaId',
  TITULO = 'titulo',
  URL = 'url',
  SITUACAO = 'situacao',
  POSICAO = 'posicao',
  DATA_INICIO = 'data_inicio',
  DATA_FIM = 'data_fim',
  DATA_HORA = 'data_hora',
  ARQUIVO = 'arquivo',
  MOBILE = 'isMobile',
}

export enum Estoque {
  CODIGO_LOJA = "cod_loja",
  VEICULOS = "veiculos",
}

export enum Veiculos {
  CODIGO_VEICULO = "cod_veiculo",
  CATEGORIA = "categoria",
  TIPO_CATEGORIA = "tipo_categoria",
  MARCA = "marca",
  MODELO = "modelo",
  VEICULO = "veiculo",
  VEICULO2 = "veiculo2",
  VERSAO = "versao",
  COR = "cor",
  MOTOR = "motor",
  ANO = "ano",
  YOUTUBE = "youtube",
  EM_OFERTA = "em_oferta",
  VALOR = "valor",
  VALAOR_OFERTA = "valor_oferta",
  OBS_SITE = "obs_site",
  FOTOS = "fotos",
  KM = "km",
  COMBUSTIVEL = "combustivel",
  OPCIONAIS = "opcionais",
  FINAL_PLACA = "finalPlaca"
}

export enum Depoimentos {
  ID = "id",
  DEPOIMENTO = "depoimento",
  NOME = "nome",
  TEMPO = "tempo",
  IMG = "img"
}

export enum Cards {
  ID = "id",
  IMG = "img",
  TEXTO = "texto",
}

export enum IframeObj {
  ID = "id",
  LINK = "link"
}

export enum Acessos {
  DIA = 'dia',
  MES = 'mes',
  ANO = 'ano',
  ACESSOS = 'acessos'
}
export enum Whats {
  CLIQUES = 'whatsappCliques',
  IP = 'ip',
  DATA = 'data_hora',
}

export enum Slide {
  SLIDES = 'slides',
  ARQUIVO = 'arquivo',
  DATA_FIM = 'data_fim',
  DATA_INICIO = 'data_inicio',
  ID = 'id',
  POSICAO = 'posicao',
  TIPO = 'tipo',
  TITULO = 'titulo',
  URL = 'url',
}

export enum EmailsAdmin {
  EMAILS = 'emails',
  ID = 'id',
  COD_LOJA = 'cod_loja',
  MENSAGEM = 'mensagem',
  NOME = 'nome',
  TELEFONE = 'telefone',
  EMAIL = 'email',
  EMAIL_LOJA = 'emailLoja',
  DATA_HORA = 'data_hora',
  DATA_CADASTRO = 'dataCadastro',
}

//================================================
// Helpers
export enum Telefones {
  TELEFONE = "telefone",
  OBS = "obs",
  WHATSAPP = "whatsapp",
}
export enum Opcionais {
  CODIGO = "codigo",
  DESCRICAO = "descricao",
}
export enum Emails {
  EMAIL = "email",
  NOME = "nome",
  OBS = "obs",
  VISIVEL_SITE = "visivel_site",
  RECEBE_PROPOSTA = "recebe_proposta",
  RECEBE_REPASSE = "recebe_repasse",
  CRITERIO = "criterio",
  TIPO = "tipo",
}
export enum Enderecos {
  BAIRRO = "bairro",
  CEP = "cep",
  CIDADE = "cidade",
  LOGRADOURO = "logradouro",
  UF = "uf",
}
