import { isMobile } from 'react-device-detect'
import * as S from './styles'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { getFuncionarios as getFuncionarioSelector } from "../../shared/redux/selectors";
import { CardFuncionarios } from "../../Pages/SobreNos/CardFuncionarios";

export const Funcionarios = () => {
    const funcionarios = useSelector(getFuncionarioSelector)
    const [index, setIndex] = useState([0,1,2])

    
  const incrementNumbers = () => {
    const lastNumber = index[index.length - 1];
    if (lastNumber === funcionarios?.length - 1) {
        setIndex([0, 1, 2]);
    } else {
        const updatedNumbers = index.map((number) => number + 1);
        setIndex(updatedNumbers);
    }
  };
  
    const decrementNumbers = () => {
        const firtNumber = index[0];
        if (firtNumber === 0) {
            setIndex([funcionarios?.length - 3, funcionarios?.length - 2, funcionarios?.length - 1]);
        } else {
            const updatedNumbers = index.map((number) => number - 1);
            setIndex(updatedNumbers);
        }
    };
    
        return (
        <S.Container>
            <h2 className="title">A NOSSA EQUIPE</h2>
            <div className="hr" />
            <div className="carrossel">
            <S.iconsDepoimentos>
                <i onClick={decrementNumbers} className="ri-arrow-left-s-line ri-xl"></i>
            </S.iconsDepoimentos>
            {isMobile ? (
                <S.DivCarrosel>
                {funcionarios?.[index[0]] ? (
                <CardFuncionarios key={funcionarios?.[index[0]]?.id} props={funcionarios?.[index[0]]} />
                ) : null}           
            </S.DivCarrosel>
            ) : (
                <S.DivCarrosel>
                {funcionarios?.[index[0]] ? (
                <CardFuncionarios key={funcionarios?.[index[0]]?.id} props={funcionarios?.[index[0]]} />
                ) : null}
                {funcionarios?.[index[1]] ? (
                <CardFuncionarios key={funcionarios?.[index[1]]?.id} props={funcionarios?.[index[1]]} />
                ) : null}
                {funcionarios?.[index[2]] ? (
                <CardFuncionarios key={funcionarios?.[index[2]]?.id} props={funcionarios?.[index[2]]} />
                ) : null}
            </S.DivCarrosel>
            )}
            
            <S.iconsDepoimentos>
                <i onClick={incrementNumbers} className="ri-arrow-right-s-line ri-xl"></i>
            </S.iconsDepoimentos>
            </div>
        </S.Container>
    )
}