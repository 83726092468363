import { Helmet } from 'react-helmet';

interface MetatagsProps {
  title: string;
  description: string;
  url: string;
  img: string;
}

function Metatags({ title, description, url, img }: MetatagsProps) {
  return (
    <Helmet>
      {/* Metatags para o Google */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={img} />
      
      {/* Metatags para o Facebook */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={img} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />


      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />

    </Helmet>
  );
}

export default Metatags;