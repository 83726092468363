import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../App"
import CookieButton from "../../components/Cookies"
import { WhatsappFlutuante } from "../../components/WhatsappFlutuante"
import { getEstoque } from "../../shared/redux/slices/litoralEstoque"
import { Header } from "../../components/Header"
import { FormPosvenda } from "./FormPosvenda"
import { TituloPosvenda } from "./TituloPosVenda"
import Metatags from "../../components/Metatags"

export const PosVenda = () => {

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(getEstoque())
    }, [dispatch])
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


    return (
        <div>
            <Metatags title="MC Veículos" description="Mc Veículos - O lugar certo para sair de carro novo!" url={window.location.href} img={`${process.env.PUBLIC_URL}/LogoLoja.ico`} />
            <Header titulo="Pós-venda" />
            <TituloPosvenda />
            <FormPosvenda />
            <WhatsappFlutuante />
            <CookieButton />
        </div>
    )
}