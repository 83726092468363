import styled from "styled-components";
import { Cores } from "../../shared/utils/styles";

export const Container = styled.div`
  background-color: ${Cores.CINZA_900};
  border-bottom: 1px solid ${Cores.BRANCO};
  padding: 15px;
  padding-right: 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  a {
    color: ${Cores.BRANCO};
    text-decoration: none;
    font-family: Montserrat;
  }
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`