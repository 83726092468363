import { Button, Select } from 'antd'
import * as S from './styles'
import { useDispatch, useSelector } from 'react-redux';
import { getInputs } from '../../../shared/redux/selectors';
import { setInputSelected, resetInput, populateInput, filterEstoque } from '../../../shared/redux/slices/litoralEstoque';
import { isMobile } from 'react-device-detect';

type FiltroTypes = {
    open?: boolean;
    setOpen?: any;
}

export const Filtro = ({open, setOpen}: FiltroTypes) => {
    const dispatch = useDispatch()
    const inputs = useSelector(getInputs);
    const url = new URL(window.location.toString());
       
    const optionsCategoria = inputs.data.categoria.map((x) => {
        return {value: x, label: x }
    })
    const optionsMarca = inputs.data.marca.map((x) => {
        return {value: x, label: x }
    })
    const optionsModelo = inputs.data.modelo.map((x) => {
        return {value: x, label: x }
    })
    
    const handleChange = (event: any, tipo: 'categoria'| 'marca' | 'modelo' | 'anoPre' | 'anoPos' | 'valorPre' | 'valorPos' | 'combustivel' | 'opcionais') => {
        dispatch(setInputSelected({index: event, tipo: tipo}))
    };

    return (

        <S.ContainerDeskTop>
            <Select
                className='custom-select'
                defaultValue="Categoria"
                value={inputs.selected.categoria !== '' ? inputs.selected.categoria : 'Categoria'}
                onChange={(event) => {
                    dispatch(resetInput({tipo:'combustivel'}))
                    dispatch(resetInput({tipo:'opcionais'}))
                    dispatch(resetInput({tipo:'valorPre'}))
                    dispatch(resetInput({tipo:'valorPos'}))
                    dispatch(resetInput({tipo:'marca'}))
                    dispatch(resetInput({tipo:'modelo'}))
                    dispatch(resetInput({tipo:'anoPos'}))
                    dispatch(resetInput({tipo:'anoPre'}))
                    handleChange(event, "categoria")
                    dispatch(populateInput())

                    url.searchParams.delete('categoria');
                    url.searchParams.set('categoria', event);
                    url.searchParams.delete('marca');
                    url.searchParams.delete('modelo');
                    url.searchParams.delete('busca');
                    window.history.pushState({}, '', url);
                }}
                options={optionsCategoria}
                suffixIcon={<i className="ri-arrow-down-s-line ri-xl" />}
                />
                <Select
                className='custom-select'
                defaultValue="Marca"
                value={inputs.selected.marca !== '' ? inputs.selected.marca : 'Marca'}
                onChange={(event) => {
                    dispatch(resetInput({tipo:'combustivel'}))
                    dispatch(resetInput({tipo:'opcionais'}))
                    dispatch(resetInput({tipo:'valorPre'}))
                    dispatch(resetInput({tipo:'valorPos'}))
                    dispatch(resetInput({tipo:'modelo'}))
                    dispatch(resetInput({tipo:'anoPos'}))
                    dispatch(resetInput({tipo:'anoPre'}))
                    handleChange(event, "marca")
                    dispatch(populateInput())

                    if(event === "Marca") {
                        url.searchParams.delete('marca');
                    } else {
                        url.searchParams.set('marca', event);
                    }
                    url.searchParams.delete('modelo');
                    url.searchParams.delete('busca');
                    window.history.pushState({}, '', url);
                }}
                options={optionsMarca}
                suffixIcon={<i className="ri-arrow-down-s-line ri-xl" />}
                />
                <Select
                    className='custom-select'
                    defaultValue="Modelo"
                    value={inputs.selected.modelo !== '' ? inputs.selected.modelo : 'Modelo'}
                    onChange={(event) => {
                        dispatch(resetInput({tipo:'combustivel'}))
                        dispatch(resetInput({tipo:'opcionais'}))
                        dispatch(resetInput({tipo:'valorPre'}))
                        dispatch(resetInput({tipo:'valorPos'}))
                        dispatch(resetInput({tipo:'anoPos'}))
                        dispatch(resetInput({tipo:'anoPre'}))
                        handleChange(event, "modelo")
                        dispatch(populateInput())

                        if(event === "Modelo") {
                            url.searchParams.delete('modelo');
                        } else {
                            url.searchParams.set('modelo', event);
                        }
                        url.searchParams.delete('busca');
                        window.history.pushState({}, '', url);
                    }}
                    options={optionsModelo}
                    suffixIcon={<i className="ri-arrow-down-s-line ri-xl" />}
                    />
                <Button onClick={() => {
                    dispatch(filterEstoque())
                }} className='button'>{isMobile ? "Buscar" : "Encontrar"}</Button>
        </S.ContainerDeskTop>
    )
}
