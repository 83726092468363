import styled from "styled-components";

export const Container = styled.div`
    padding: 10px 0;
    display: flex;
    margin-top: -60px;
    .input-buscar {
        border-radius: 100px;
        font-family: Montserrat;
        padding: 10px 20px;
        width: 600px !important;
    }
    i {
        color: #CD8A49;
        cursor: pointer;
    }
    >div{
        margin: 0px 10px;
        display: flex;
        flex-direction: column;
        >div{
            max-width: 500px;
            margin: auto;
            display: flex;
            justify-content: center;
            >input{
                border: none;
                width: 100%;
                padding: 0px 9px;
            }
        }
    }
`
