import styled from "styled-components";
import { Cores } from "../../shared/utils/styles";

export const Container = styled.div`
    .bold {
        font-weight: 600;
        color: red;
    }
`

export const Opcoes = styled.div`
        
    .header-video{
        justify-content: end;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 20px;
        z-index: 10;
        transition: all 0.5s ease-in-out;
        animation: center-animate 1s ease infinite alternate;
        img {
            transition: all .2s ease-in-out;
            height: 30px;
            margin: 5px 0;
        }
        
    }

    .header-default {
        .options-header-default {
            display: flex;
            flex-direction: row;
            gap: 40px;
            .header-default-links {
                color: ${Cores.BRANCO};
                font-size: small;
            }
            .dropdown-default {
                color: ${Cores.BRANCO};
                font-size: 0px;
                font-family: Poppins;
            }
            .css-dev-only-do-not-override-ph9edi{
                font-size: small !important;

            }
        }
        background-color: ${Cores.PRETO};
        opacity: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 20px;
        z-index: 10;
        transition: all 0.5s ease-in-out;
        animation: center-animate 1s ease infinite alternate;
        img {
            transition: all .2s ease-in-out;
            height: 30px;
            margin: 5px 0;
        }
        
    }

    .dropdown {
        z-index: 99;
    }
    img {
        background-color: transparent;
    }
    >div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
        background-color: transparent;
    }
    a {
        background-color: transparent;
        text-decoration: none;
        color: ${Cores.BRANCO};
        font-size: larger;
        font-family: Montserrat;
        font-weight: 500;
    }
    .dropdown-text {
        background-color: transparent;
        text-decoration: none;
        color: ${Cores.BRANCO};
        font-size: xx-large;
        font-weight: bold;
        cursor: pointer;
    }
`
