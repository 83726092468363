import { Form, Input, Select } from 'antd'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postEmailAvaliacao } from '../../../service/apiLitoral';
import { getLoja } from '../../../shared/redux/selectors';
import { populateInput, resetInput, setInputSelected } from '../../../shared/redux/slices/litoralEstoque';
import { EnvioEmailAvaliacao as EnvioEmailAvaliacaoDictionary } from '../../../shared/utils/dictionary';
import InputMask from 'react-input-mask';
import * as S from './styles'
import { toast } from 'react-toastify';
import imgBack from "../../../assets/images/mcBackgroundForm.png"
import { isMobile } from 'react-device-detect';

type FormAvaliacaoType = {
    [EnvioEmailAvaliacaoDictionary.ANO_FABRICACAO]: string;
    [EnvioEmailAvaliacaoDictionary.ANO_MODELO]: string;
    [EnvioEmailAvaliacaoDictionary.CELULAR]: string;
    [EnvioEmailAvaliacaoDictionary.COMBUSTIVEL]: string;
    [EnvioEmailAvaliacaoDictionary.COR]: string;
    [EnvioEmailAvaliacaoDictionary.EMAIL]: string;
    [EnvioEmailAvaliacaoDictionary.MARCA]: string;
    [EnvioEmailAvaliacaoDictionary.MODELO]: string;
    [EnvioEmailAvaliacaoDictionary.MOTIVO]: string;
    [EnvioEmailAvaliacaoDictionary.NOME]: string;
}

export const FormAvaliacao = () => {
    const [form] = Form.useForm();
    const [telefone, setTelefone] = useState('');
    const loja = useSelector(getLoja)     
    const dispatch = useDispatch()
    const [etapa, setEtapa] = useState(false)

    const handleChange = (event: any, tipo: 'categoria'| 'marca' | 'modelo' | 'anoPre' | 'anoPos' | 'valorPre' | 'valorPos' | 'combustivel' | 'opcionais') => {
        dispatch(setInputSelected({index: event, tipo: tipo}))
    };

    const onFinish = (values: FormAvaliacaoType) => {   
        
        const data = {
            [EnvioEmailAvaliacaoDictionary.ANO_FABRICACAO]: values.ano_fabricacao,
            [EnvioEmailAvaliacaoDictionary.ANO_MODELO]: values.ano_modelo,
            [EnvioEmailAvaliacaoDictionary.CELULAR]: values.celular,
            [EnvioEmailAvaliacaoDictionary.COMBUSTIVEL]: values.combustivel,
            [EnvioEmailAvaliacaoDictionary.COR]: values.cor,
            [EnvioEmailAvaliacaoDictionary.EMAIL]: values.email,
            [EnvioEmailAvaliacaoDictionary.MARCA]: values.marca,
            [EnvioEmailAvaliacaoDictionary.MODELO]: values.modelo,
            [EnvioEmailAvaliacaoDictionary.MOTIVO]: values.motivo,
            [EnvioEmailAvaliacaoDictionary.NOME]: values.nome,
            [EnvioEmailAvaliacaoDictionary.EMAIL_LOJA]: loja.emails[1].email ? loja.emails[1].email : "mcveiculosmkt@gmail.com",
        }
        postEmailAvaliacao(data)
        toast.success("Email enviado com sucesso!")
        dispatch(resetInput({tipo:'modelo'}))
        dispatch(resetInput({tipo:'marca'}))
        dispatch(populateInput())
        form.resetFields();
      };
      
      const onFinishFailed = () => {
        setEtapa(false)
        toast.error("Preencha todos os campos!")
      };

    return (
        <S.Container>
            <Form
                form={form}
                className='form'
                name="avaliacao"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className='titulo-form'>
                    <h2>Avaliar o seu</h2>
                    <h2 className='yellow'>Veículo</h2>
                </div>
                <div style={!etapa ? {display: "flex", flexDirection: "column" } : {display: "none"}}>
                <Form.Item
                name="motivo"
                rules={[{ required: true, message: 'Insira o motivo da avaliação!' }]}
                >
                    <Select
                    suffixIcon={<i className="ri-arrow-down-s-line ri-xl"></i>}
                    defaultValue="Motivo da avaliação"
                    options={[
                        { value: 'Motivo da avaliação', label: 'Motivo da avaliação' },
                        { value: 'Quero vender meu carro', label: 'Quero vender meu carro' },
                        { value: 'Quero trocar meu carro', label: 'Quero trocar meu carro' },
                        { value: 'Quero consignar meu carro', label: 'Quero consignar meu carro' },
                    ]}
                    />
                </Form.Item>
                <Form.Item
                name="marca"
                rules={[{ required: true, message: 'Insira a marca!' }]}
                >
                    <Input
                    placeholder="Marca"
                    onChange={(event) => {
                        dispatch(resetInput({tipo:'modelo'}))
                        handleChange(event, "marca")
                        dispatch(populateInput())
                    }}
                    />
                </Form.Item>
                <Form.Item
                name="modelo"
                rules={[{ required: true, message: 'Insira o modelo do veículo!' }]}
                >
                    <Input
                    placeholder="Modelo"
                    onChange={(event) => {
                        dispatch(populateInput())
                    }}
                    />
                </Form.Item>
                <div>
                    <Form.Item
                    name="ano_fabricacao"
                    rules={[{ required: true, message: 'Insira o ano de fabricação do veículo!' }]}
                    >
                        <Input
                        placeholder="Ano fabricação"
                        onChange={(event) => {
                            dispatch(populateInput())
                        }}
                        />
                    </Form.Item>
                    <Form.Item
                    name="ano_modelo"
                    rules={[{ required: true, message: 'Insira o ano do modelo do veículo!' }]}
                    >
                        <Input
                        placeholder="Ano modelo"
                        onChange={() => {
                            dispatch(populateInput())
                        }}
                        />
                    </Form.Item>
                </div>
                <Form.Item
                name="cor"
                rules={[{ required: true, message: 'Insira a cor!' }]}
                >
                    <Input placeholder='Cor' />
                </Form.Item>
                <Form.Item
                    name="combustivel"
                    rules={[{ required: true, message: 'Insira o combustivel do veículo!' }]}
                    >
                        <Input
                        placeholder="Combustível"
                        onChange={() => {
                            dispatch(populateInput())
                        }}
                        />
                    </Form.Item>
                    </div>
                    <div style={!etapa ? {display: "none"} : {display: "flex", flexDirection: "column" }}>
                    <Form.Item
                    name="nome"
                    rules={[{ required: true, message: 'Insira seu nome!' }]}
                    >
                        <Input placeholder='Nome' />
                    </Form.Item>
                    <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Insira seu email!' }]}
                    >
                        <Input placeholder='Email' />
                    </Form.Item>
                    <Form.Item
                    name="celular"
                    rules={[{ required: true, message: 'Insira seu telefone!' }]}
                    >
                    <InputMask
                        mask="(99) 99999-9999"
                        placeholder='Telefone'
                        value={telefone}
                        onChange={(e) => setTelefone(e.target.value)}
                        />
                </Form.Item>
                </div>
                <div className='botoes'>
                    <button style={etapa ? {display: "none"} : {display: "block"}} onClick={() => {
                        form.resetFields();
                    }} type="button" className='limpar'>Limpar</button>
                    <button style={etapa ? {display: "block"} : {display: "none"}} onClick={() => {
                        setEtapa(false)
                    }} type="button" className='limpar'>Voltar</button>
                    <button type="button" onClick={() => {
                        setEtapa(true)
                    }} style={etapa ? {display: "none"} : {display: "block"}}>Próxima etapa</button>
                    <button style={etapa ? {display: "block"} : {display: "none"}} type='submit'>Enviar E-mail</button>
                </div>
                </Form>
                {isMobile ? null : <img src={imgBack} alt="MC" />}
        </S.Container>
    )
}