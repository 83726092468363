import * as S from './style'
import semImagem from '../../assets/images/semImagem.png'
import { Veiculos } from '../../shared/types'
import { Veiculos as VeiculosDictionary } from '../../shared/utils/dictionary'
import { useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons';

type CardVeiculoProps = {
  veiculo: Veiculos;
  codLoja: string
}
export default function CardVeiculo({ veiculo, codLoja }: CardVeiculoProps) {

  const [imagemLoad, setImagemLoad] = useState(true)
  const [imagemError, setImagemError] = useState(false)

  const handleImagemLoad = () => {
    setImagemLoad(false)
  }
  
  const redirecionaPaginaVeiculo = () => {
    const data = {
      veiculo: veiculo[VeiculosDictionary.MARCA],
      modelo: veiculo[VeiculosDictionary.MODELO],
      ano: veiculo[VeiculosDictionary.ANO],
      codVeiculo: veiculo[VeiculosDictionary.CODIGO_VEICULO] 
    };
    const url = new URLSearchParams(data).toString();
    window.open(`/veiculo/?${url}`, "_self");
  }

  const handleImagemError = (event: { target: any; }) => {
    setImagemError(true)
    const imagem = event.target;
    // imagem.style.display = 'none';
  }

  const img = `https://litoralcar.com.br/foto-resize-site/X/${veiculo?.cod_veiculo}/${process.env.REACT_APP_COD_LOJA}/${veiculo?.fotos[0]}`
  
  return (
    <S.Card>
      {/* {imagemError && <img src={semImagem} alt="Error ao carregar Imagem" onClick={() => {redirecionaPaginaVeiculo()}}/>} */}
      {imagemLoad && !imagemError && <S.LoadingCard><LoadingOutlined /></S.LoadingCard>}
      <img src={img} className="imagem-veiculo" id={`imagem_${veiculo?.[VeiculosDictionary.CODIGO_VEICULO]}`} alt={veiculo?.[VeiculosDictionary.MODELO]} onError={handleImagemError} onLoad={handleImagemLoad} onClick={() => {redirecionaPaginaVeiculo()}} />
      <div>
        <S.Veiculo>
          <S.InfoCard>
            <span>
              <h2><b>{veiculo?.[VeiculosDictionary.MARCA]} {veiculo?.[VeiculosDictionary.MODELO]} {veiculo?.[VeiculosDictionary.VERSAO] !== "" ? `${veiculo?.[VeiculosDictionary.VERSAO]} ${veiculo?.[VeiculosDictionary.MOTOR]}` : `${veiculo?.[VeiculosDictionary.VEICULO2]}`}</b></h2> 
            </span>
              <h5>{veiculo?.[VeiculosDictionary.ANO]} - {veiculo?.[VeiculosDictionary.COR]}</h5>
              <h4>{Number(veiculo?.[VeiculosDictionary.VALOR]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h4>
            <div className='container-footer'>
            </div>
          </S.InfoCard>
        </S.Veiculo>
      </div>
    </S.Card>
  )
}
