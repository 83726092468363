import { AiFillFacebook, AiFillSetting } from 'react-icons/ai'
import { AsideAdmin } from '../../../components/AsideAdmin'
import { HeaderAdmin } from '../../../components/HeaderAdmin'
import { putInfos } from '../../../service/apiLitoral'
import { InfosTabela } from '../../../shared/types'
import { InfosLoja as InfosLojaDictionary } from '../../../shared/utils/dictionary'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as S from './styles'
import { useEffect, useState } from 'react'
import { Button, Form, Input } from 'antd'
import { Cores } from '../../../shared/utils/styles'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../App'
import { getInfoLoja } from '../../../shared/redux/slices/litoralAdmin'
import { getInfoLoja as getInfoLojaSelector } from '../../../shared/redux/selectors'
import { BsInstagram } from 'react-icons/bs'
import { toast } from 'react-toastify'

export const Configuracoes = () => {
    const [form] = Form.useForm();
    const [conteudo, setConteudo] = useState('');
    const dispatch = useDispatch<AppDispatch>()
    const loja = useSelector(getInfoLojaSelector)

    const onFinish = (data: InfosTabela) => {
        const dataEnvio = {
            [InfosLojaDictionary.DESCRICAO]: conteudo,
            [InfosLojaDictionary.URL_INSTAGRAM]: data.urlInstagram ? data.urlInstagram :loja.urlInstagram,
            [InfosLojaDictionary.URL_FACEBOOK]: data.urlFacebook ? data.urlFacebook :loja.urlFacebook,
            [InfosLojaDictionary.HORAIRO_SEG_SEX]: data.horarioSegSex ? data.horarioSegSex :loja.horarioSegSex,
            [InfosLojaDictionary.HORAIRO_SAB]: data.horarioSab ? data.horarioSab :loja.horarioSab,
            [InfosLojaDictionary.HORAIRO_DOM]: data.horarioDomingo ? data.horarioDomingo :loja.horarioDomingo
        }

        putInfos(dataEnvio)
        toast.success("Informações atualizadas com sucesso")
    }

    useEffect(()=>{
        dispatch(getInfoLoja())
    },[])

    useEffect(() => {
        if(loja) {
            form.setFieldsValue(loja);
        }
        setConteudo(loja.descricao)
    },[loja])

    return (
        <S.Container>
            <AsideAdmin />
            <HeaderAdmin />
            <div className='title'>
                <AiFillSetting size={40} />
                <h1>Configurações</h1>
            </div>
            <h2>Editar configurações</h2>
            <div className='content'>
                <ReactQuill defaultValue={loja.descricao} style={{minHeight: 450, maxHeight: 450, minWidth: 600, maxWidth: 650, paddingBottom: 30}} value={conteudo} onChange={(value) => {setConteudo(value)}} />
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={onFinish}
                    >
                    <h3>Instagram <BsInstagram /></h3>
                    <Form.Item name="urlInstagram">
                        <Input />
                    </Form.Item>
                    <h3>Facebook <AiFillFacebook /></h3>
                    <Form.Item name="urlFacebook">
                        <Input />
                    </Form.Item>
                    <h3>Horários</h3>
                    <div className='inline-inputs'>
                        <Form.Item name="horarioSegSex" label="Segunda a sexta:">
                            <Input/>
                        </Form.Item>
                        <Form.Item name="horarioSab" label="Sábado:">
                            <Input/>
                        </Form.Item>
                    </div>
                        <Form.Item name="horarioDomingo" label="Domingo:">
                            <Input />
                        </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" style={{backgroundColor: Cores.VERDE_600}} type="primary">Salvar</Button>
                    </Form.Item>
                    </Form>
                </div>
        </S.Container>
    )
}