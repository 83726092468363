import * as S from './styles'
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVeiculo as getVeiculoSelector, getLoja as getLojaSelector, getEstoque } from '../../../shared/redux/selectors';
import { Veiculos as VeiculosDictionary, Loja as LojaDictionary } from '../../../shared/utils/dictionary';
import { FormProposta } from '../conteudoPagina/FormProposta';
import { CaroselVeiculo } from './CaroselVeiculo';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { getLoja } from "../../../shared/redux/slices/litoralLoja"
import { AppDispatch } from '../../../App';
import CardVeiculo from '../../../components/CardVeiculo';
import { isMobile } from 'react-device-detect';
import {
    TransformWrapper,
    TransformComponent,
  } from "react-zoom-pan-pinch";
import React from 'react';

type ConteudoPaginaProps = { codVeiculo: string }
export const ConteudoPagina = ({ codVeiculo }: ConteudoPaginaProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const loja = useSelector(getLojaSelector)
    const veiculo = useSelector(getVeiculoSelector)
    const [loading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const estoque = useSelector(getEstoque)
    const [openOpcionais, setOpenOpcionais] = useState(false);
    const [doubleStep, setDoubleStep] = useState(8);

    useEffect(() => {
        if (veiculo.fotos.length > 1) {
            setLoading(false)
        }
        console.log(loading)
    }, [veiculo])

    useEffect(() => {
        dispatch(getLoja())
    }, [dispatch])
    const handlePrevClick = () => {
        setActiveIndex((activeIndex - 1 + veiculo.fotos.length) % veiculo.fotos.length);
    };
    const handleNextClick = () => {
        setActiveIndex((activeIndex + 1) % veiculo.fotos.length);
    };
    useEffect(() => {
        function handleKeyDown(event: { code: string; }) {
            if (event.code === 'ArrowLeft') {
                setActiveIndex((activeIndex - 1 + veiculo.fotos.length) % veiculo.fotos.length);
            } else if (event.code === 'ArrowRight') {
                setActiveIndex((activeIndex + 1) % veiculo.fotos.length);
            } else if (event.code === 'Escape') {
                setShowModal(false)
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [activeIndex, veiculo.fotos.length]);

    const [showModall, setShowModal] = useState(false);
    const link = window.location.origin + window.location.pathname + window.location.search;

    function generateRandomNumbers(): number[] {
        const numbers: number[] = [];
        while (numbers.length < 3) {
            const random = Math.floor(Math.random() * 50) + 1;
            if (!numbers.includes(random)) {
                numbers.push(random);
            }
        }
        return numbers;
    }


    const randomNumbers = generateRandomNumbers();
    
    const cambio = veiculo.opcionais.findIndex((x) => x.codigo === 21)

    const divRef = useRef<HTMLImageElement>(null);
    const ignoreFirstClickRef = useRef(true);

    const handleClickOutside = (event: MouseEvent) => {
        if (ignoreFirstClickRef.current) {
            ignoreFirstClickRef.current = false;
            return;
        }

        if (divRef.current && !divRef.current.contains(event.target as Node) && !isButtonClick(event.target as HTMLElement)) {
            setShowModal(false)
            ignoreFirstClickRef.current = true;
        }
    };

    const isButtonClick = (target: HTMLElement): boolean => {
        if (target.classList.contains('button-class')) {
            return true;
        }

        if (target.parentElement) {
            return isButtonClick(target.parentElement);
        }

        return false;
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const [startXImg, setStartXImg] = useState(null);
    const [endX, setEndX] = useState(null);

    const handleTouchStart = (event: any) => {
        setStartXImg(event.touches[0].clientX);
    };

    const handleTouchMove = (event: any) => {
        setEndX(event.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (startXImg !== null && endX !== null) {
            const deltaX = endX - startXImg;

            if (deltaX > 0) {
                handlePrevClick()
            } else if (deltaX < 0) {
                handleNextClick()
            }

            setStartXImg(null);
            setEndX(null);
        }
    };

    const Controls = ({ zoomIn, zoomOut, resetTransform } : any) => (
        <div className='zoom-buttons'>
          <button onClick={() => {
            zoomIn()
            }}><i style={{color: "white"}} className="ri-zoom-in-line ri-xl"></i></button>
          <button onClick={() => {
            zoomOut()
            }}><i style={{color: "white"}} className="ri-zoom-out-line ri-xl"></i></button>
        </div>
      );
    
    return (
        <S.Container>
            {showModall && (
                <S.StyledModal>
                    <S.ArrowWrapper className='button-class' style={{ left: 0, marginLeft: 20 }} onClick={handlePrevClick}>
                        <MdChevronLeft size={30} />
                    </S.ArrowWrapper>
                    <S.ArrowWrapper style={{ right: 0, marginRight: 20 }} onClick={handleNextClick}>
                        <MdChevronRight className='button-class' size={30} />
                    </S.ArrowWrapper>
                    <div ref={divRef}>
                    <TransformWrapper
                     doubleClick={{step: doubleStep}}
                     onPanningStop={(e) => {
                       if (e.state.scale !== 1) {
                         setDoubleStep(-8);
                       }
                       else {
                         setDoubleStep(8);
                       }
                     }}
                    >
                        {(utils) => (
                            <React.Fragment>
                            <Controls {...utils} />
                            <TransformComponent>
                            <img
                                id="imgExample"
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd} 
                                src={`https://litoralcar.com.br/foto-resize-site/X/${codVeiculo}/${process.env.REACT_APP_COD_LOJA}/${veiculo.fotos[activeIndex]}`} alt="Imagem veículo" />
                            </TransformComponent>
                            </React.Fragment>
                        )}
                        </TransformWrapper>
                    </div>
                </S.StyledModal>
            )}
            <div>
                <div className='container'>
                    <div className='carro-info'>
                        <div className='button-class'>
                            <CaroselVeiculo activeIndex={activeIndex} setActiveIndex={setActiveIndex} setImageOpen={setShowModal} codVeiculo={veiculo.cod_veiculo} fotos={veiculo.fotos} />
                        </div>
                        <h2 className='titulo-info'>{veiculo.marca} <span>{veiculo.modelo}</span></h2>
                        <h3 className='versao'>{veiculo.versao}</h3>
                        <div className='infos'>
                            <span>
                                <h5 className='yellow'>Ano</h5>
                                <h4>{veiculo.ano}</h4>
                            </span>
                            <span>
                                <h5 className='yellow'>Câmbio</h5>
                                <h4>{cambio >= 0 ? "Automático" : "Manual"}</h4>
                            </span>
                            <span>
                                <h5 className='yellow'>Cor</h5>
                                <h4>{veiculo.cor}</h4>
                            </span>
                            <span>
                                <h5 className='yellow'>Combustível</h5>
                                <h4>{veiculo.combustivel}</h4>
                            </span>
                            <span>
                                <h5 className='yellow'>Quilometragem</h5>
                                <h4>{Number(veiculo[VeiculosDictionary.KM]).toLocaleString('pt-BR')}</h4>
                            </span>
                            <span>
                                <h5 className='yellow'>Motor</h5>
                                <h4>{veiculo.motor}</h4>
                            </span>
                            <span>
                                <h5 className='yellow'>Final da placa</h5>
                                <h4>{veiculo.finalPlaca}</h4>
                            </span>
                        </div>
                        <hr />
                        <h5 className='yellow'>Opcionais</h5>
                        <div className='opcionais'>
                            {isMobile ? (
                                <>
                                    {openOpcionais ? (
                                        <ul>
                                            {veiculo?.opcionais?.map((item, index) => (
                                                <li key={index}>{item.descricao}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <ul>
                                            {veiculo?.opcionais?.slice(0, 10).map((item, index) => (
                                                <li key={index}>{item.descricao}</li>
                                            ))}
                                        </ul>
                                    )}
                                    {veiculo?.opcionais.length > 10 ? (
                                        openOpcionais ? <i id="open" onClick={() => {
                                            setOpenOpcionais(false)
                                        }} className="ri-arrow-drop-up-line ri-xl"></i> :
                                            <i id="open" onClick={() => {
                                                setOpenOpcionais(true)
                                            }} className="ri-arrow-down-s-line ri-lg"></i>
                                    ) : null}


                                </>
                            ) : (
                                <ul>
                                    {veiculo?.opcionais?.map((item, index) => (
                                        <li key={index}>{item.descricao}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <hr />
                        <h5 className='yellow'>Observações</h5>
                        <h4 className='obs'>{veiculo.obs_site}</h4>
                        <h5 className='yellow'>Compartilhar</h5>
                        <div className='compartilhar'>
                            <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}&quote=Aqui na ${loja[LojaDictionary.NOME_EMPRESA]} você encontra ${veiculo[VeiculosDictionary.MARCA]} ${veiculo[VeiculosDictionary.MODELO]} ${veiculo[VeiculosDictionary.MOTOR]} ${veiculo[VeiculosDictionary.ANO]} para negociação. Acesse e confira! ${link}`} target='_blank'>
                                <i style={{ color: '#0057FF' }} className="ri-facebook-fill ri-xl"></i>
                            </a>
                            <a href={`https://twitter.com/intent/tweet?text=Aqui na ${loja[LojaDictionary.NOME_EMPRESA]} você encontra ${veiculo[VeiculosDictionary.MARCA]} ${veiculo[VeiculosDictionary.MODELO]} ${veiculo[VeiculosDictionary.MOTOR]} ${veiculo[VeiculosDictionary.ANO]} para negociação. Acesse e confira! ${encodeURIComponent(link)}`} target='_blank'>
                                <i style={{ color: '#00C2FF' }} className="ri-twitter-fill ri-xl"></i>
                            </a>
                            <a href={`https://api.whatsapp.com/send?text=Aqui na ${loja[LojaDictionary.NOME_EMPRESA]} você encontra ${veiculo[VeiculosDictionary.MARCA]} ${veiculo[VeiculosDictionary.MODELO]} ${veiculo[VeiculosDictionary.MOTOR]} ${veiculo[VeiculosDictionary.ANO]} para negociação. Acesse e confira! ${encodeURIComponent(link)}`} target='_blank'>
                                <i style={{ color: '#20B038' }} className="ri-whatsapp-line ri-xl"></i>
                            </a>
                        </div>
                    </div>
                    <div className='form'>
                        <FormProposta veiculo={veiculo} />
                    </div>
                </div>
                <div className='interesse'>
                    <h2 className='titulo'>PODE TAMBÉM LHE INTERESSAR</h2>
                    <div className='hr' />
                    <div className='grid'>
                        <CardVeiculo codLoja={loja.cod_loja} veiculo={estoque[randomNumbers[0]]} />
                        <CardVeiculo codLoja={loja.cod_loja} veiculo={estoque[randomNumbers[1]]} />
                        <CardVeiculo codLoja={loja.cod_loja} veiculo={estoque[randomNumbers[2]]} />
                    </div>
                </div>
            </div>
        </S.Container>
    )
}

