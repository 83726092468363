import styled from "styled-components";

export const Container = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    margin: 70px auto 30px;
    >h3{
        font-size: 17px;
        font-weight: 600;
    }
    .hr {
        height: 1px;
        background: linear-gradient(to right, #CD8A49 0%, #F8E58F 100%);
        border-radius: 5px;
        width: 140px;
    }
`