import { WhatsappFlutuante } from "../../components/WhatsappFlutuante"
import { Header } from "../../components/Header"
import { getEstoque, getVeiculo } from "../../shared/redux/slices/litoralEstoque"
import { getVeiculo as getVeiculoSelector } from "../../shared/redux/selectors"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch } from "../../App"
import { ConteudoPagina } from "./conteudoPagina"
import { ScrollTopo } from "../../components/ScrollTopo"
import Metatags from "../../components/Metatags"

export const Veiculo = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams);
    
    const dispatch = useDispatch<AppDispatch>()
    const veiculo = useSelector(getVeiculoSelector)

    useEffect(() => {
        dispatch(getVeiculo(params.codVeiculo))
        dispatch(getEstoque())
    }, [dispatch, params.codVeiculo])
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    const MetaTitle = `MC Veículos - ${veiculo.marca} ${veiculo.modelo}`

    return (
        <div style={{minHeight:"100vh"}}>
            <Metatags title={MetaTitle} description={`Aqui na Mc Veículos você encontra ${veiculo.marca} ${veiculo.modelo} ${veiculo.motor} ${veiculo.ano} para negociação. Acesse e confira!`} url={window.location.href} img={`https://litoralcar.com.br/foto-resize-site/X/${params.codVeiculo}/${process.env.REACT_APP_COD_LOJA}/${veiculo.fotos[0]}`}/>
            <Header titulo="Veículo" />
            <ScrollTopo/>
            <ConteudoPagina codVeiculo={params.codVeiculo}/>
            <WhatsappFlutuante />
        </div>
    )
}