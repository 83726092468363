import { Depoimentos } from "../../../../shared/types";
import * as S from "./styles";
import { Depoimentos as DepoimentosDictionary} from "../../../../shared/utils/dictionary";
import estrelas from '../../../../assets/images/estrelas.png'

type DepoimentoProps = { depoimento: Depoimentos };

export const CardDepoimentos = ({ depoimento }: DepoimentoProps) => {
  return (
    <S.Container>
      <span>
        <img className="usuario" src={depoimento[DepoimentosDictionary.IMG]} alt={depoimento[DepoimentosDictionary.NOME]} />
        <div>
          <h2>{depoimento[DepoimentosDictionary.NOME]}</h2>
          <img src={estrelas} alt="Avaliação" />
        </div>
      </span>
      <h3>{depoimento[DepoimentosDictionary.DEPOIMENTO]}</h3>
    </S.Container>
  );
};
