import styled from "styled-components";
import { Cores } from "../../../../shared/utils/styles";

export const Container = styled.div`
    
   padding: 70px;

    h2 {
        font-weight: 700;
        font-size: 40px;
    }
    .titulo-form {
        width: 100%;
        display: flex;
        float: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    }
    .yellow {
        color: #CE8C4B;
    }
    @media (max-width: 991px) {
            .titulo-form {
                justify-content: start;
            }
            h2 {
                font-size: 23px !important;
            }
        }
    #contato_telefone {
        width: 100%;
        border-radius: 5px;
        height: 42px;
        outline: none;
        border: none;
        background-color: transparent;
        color: white;
        font-size: 15px;
        border: 1px solid #393737;
        color: white;
        font-family: Montserrat;
        ::placeholder {
            color: #828282;
            padding: 0 9px;
        }
    }
    .ant-input {
        border: 1px solid #393737;
        background-color: transparent;
        color: white;
        font-family: Montserrat;
        font-size: 15px;
        padding: 8px 16px;
        padding: 8px;
        ::placeholder {
            color: #828282;
        }
    }
    .ant-row {
        width: 100%;
    }
    .ant-form-item-margin-offset {
        display: none;
    }
    button{
        height: 40px;
        width: 100%;
        margin: auto;
        font-weight: bold;
        transition: 0.4s all;
        cursor: pointer;
        background: linear-gradient(269.62deg, #CE8C4B 47.28%, #F8E68F 100%);
        border-radius: 20px;
        border: none;
        color: white;
        font-family: Montserrat;
        font-weight: 500;
        background-color: ${Cores.LARANJA_300};
        :hover{
            opacity: 0.7;
        }
        }
        @media (max-width: 991px) {
            padding: 25px 15px 0px 15px;   
        }
`