import * as S from './styles'
import { IframeObj } from '../../../../shared/Constantes'
import { IframeObj as IframeObjDictionary } from '../../../../shared/utils/dictionary'

export const MapasContato = () => {
    
    return (
        <S.Container>
            {IframeObj.map((link) => {
                return (
                    <S.DivIframe>
                        <iframe title='Mapa' src={link[IframeObjDictionary.LINK]}></iframe>
                    </S.DivIframe>
                )
            })}
        </S.Container>
    )
}