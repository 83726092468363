import styled from "styled-components";
import { Cores } from "../../../shared/utils/styles";

export const Container = styled.div`
    max-width: 70vw;
    margin: 30px auto;
    padding: 50px;
    .loading-div {
        display: flex;
        justify-content: center;
        align-items: center;
        >img {
            max-width: 100px;
        }
    }

    .container {
        padding-bottom: 0;
    }
    .custom-modal .ant-modal-content {
        background-color: red !important;
    }
    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: self-start;
        gap: 40px;
    }
    .carro-info {
        background-color: #141414;
        padding: 30px;
        border-radius: 15px 0px 0px 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .form {
        background-color: #141414;
        padding: 30px;
        position: sticky;
        top: 70px;
        border-radius: 0px 15px 15px 0px;
    }

    .hr {
        width: 150px;
        height: 1px;
        background: linear-gradient(to right, #CD8A49 0%, #F8E58F 100%);
        border-radius: 5px;
    }
    .titulo {
        color: white;
        font-weight: 500;
        font-size: 19px;
    }
    .interesse {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    .grid {
        display: flex;
        flex-direction: row;
    }
    .titulo-info {
        color: white;
        margin-top: 20px;
        font-weight: 400;
        >span {
            font-weight: 600;
            background: radial-gradient(83.82% 83.82% at 50% 16.18%, #F8E68F 0%, #CE8C4B 78.12%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }
    .versao {
        color: white;
        font-weight: 400;
        font-size: 17px;
    }
    .infos {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 20px;
    }
    h4 {
        color: white;
        font-weight: 400;
    }
    .yellow {
        background: radial-gradient(58.33% 58.33% at 50% 41.67%, #E7C374 0%, #CE8C4B 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 5px;
    }
    hr {
        margin-top: 20px;
        margin-bottom: 20px;
        border: 1px solid #272727;
    }
    ul {
        list-style-type: disc;
        color: white;
        font-family: Montserrat;
        font-size: 15px;
        column-count: 4;
        margin-left: 20px;
    }
    li {
        margin-top: 10px;
    }
    .obs {
        font-size: 16px;
        margin-bottom: 15px;
        text-align: justify;
    }
    .compartilhar {
        display: flex;
        flex-direction: row;
        gap: 10px;
        >i {
            cursor: pointer;
        }
    }
    a {
        text-decoration: none;
    }

    @media (max-width: 991px) {
        padding: 50px 10px;
        max-width: 98vw;
        .carro-info {
            padding: 20px;
        }
        .container {
            flex-direction: column;
        }
        .grid {
            flex-direction: column;
        }

        .infos {
            grid-template-columns: 1fr 1fr;
        }
        ul {
            column-count: 1;
        }
        .form {
            width: 100%;
        }
        #open {
            color: #F8E68F;
            justify-content: end;
            display: flex;
            cursor: pointer;
        }
        .obs {
            width: 98%;
        }
    }
    @media (max-width: 991px) {
        .carro-info {
        border-radius: 15px;
    }
    .form {
        border-radius: 15px;
    }
    }
`

export const LoadingCard = styled.div`
    display: flex;
    align-items: center;
    height: 309.86px;
    justify-content: center;
    background-color: ${Cores.CINZA_300};
    >span{
        color: ${Cores.BRANCO};
        font-size: 35px;
        padding: 20px;
    }
    `

export const ImageWrapper = styled.div<{ active: boolean }>`
  position: absolute;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  transition: background-color 0.5s ease;
  z-index: 99999;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  img {
    max-height: 90vh;
    max-width: 90vw;
  }
  .zoom-buttons {
    display: flex;
    justify-content: end;
    >button {
        padding: 10px;
        background-color: rgba(30,30,30,.6);
        outline: none;
        border: none;
        cursor: pointer;
    }
  }
`;

export const ModalImage = styled.img`
  max-height: 90vh;
  max-width: 90vw;
`;
