import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import rootReducer from "./shared/redux/slices";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import { postVisitas } from "./service/apiLitoral";
import { isMobile } from 'react-device-detect';
import axios from "axios";
import { getEmails, getEnderecos, getLoja, getTelefones } from "./shared/redux/slices/litoralLoja";
import { Rodape } from "./components/Rodape";

const store = configureStore({reducer: rootReducer});
export type AppDispatch = typeof store.dispatch

function App() {
  const [ip, setIP] = useState()

  const getData = async () => {

    axios.get('https://api.ipify.org?format=json')
    .then(response => {
      setIP(response.data.ip);
    })
    .catch(error => {
      console.log(error);
    });
    
    };

    useEffect(()=> {
        store.dispatch(getTelefones())
        store.dispatch(getEmails())
        store.dispatch(getLoja())
        store.dispatch(getEnderecos())
    },[])
    
  useEffect(()=> {
    getData()
    if(!ip) return
    postVisitas({
      ip: ip,
      dispositivo: isMobile ? 'MOBILE' : 'PC',
      lojaId: Number(process.env.REACT_APP_COD_LOJA)
  })
  },[ip])
  return (
    <div className="App">
       <ToastContainer style={{zIndex: 9999999}} />
      <Provider store={store}>
      <BrowserRouter>
      <div className="container">
        <Routes />
      </div>
        {!window.location.pathname.includes('admin') ? (<Rodape />): null}
      </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;