import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../App"
import CookieButton from "../../components/Cookies"
import { WhatsappFlutuante } from "../../components/WhatsappFlutuante"
import { getEstoque } from "../../shared/redux/slices/litoralEstoque"
import { Header } from "../../components/Header"
import { FormAvaliacao } from "./FormAvaliacao"
import { TituloAvaliacao } from "./TituloAvaliacao"
import Metatags from "../../components/Metatags"
import { getLoja } from "../../shared/redux/slices/litoralLoja"

export const Avaliacao = () => {
    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => {
        dispatch(getLoja())
        dispatch(getEstoque())
    }, [dispatch])
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div>
            <Metatags title="MC Veículos" description="Mc Veículos - O lugar certo para sair de carro novo!" url={window.location.href} img={`${process.env.PUBLIC_URL}/LogoLoja.ico`} />
            <Header titulo="Avaliação" />
            <TituloAvaliacao />
            <FormAvaliacao />
            <WhatsappFlutuante />
            <CookieButton />
        </div>
    )
}