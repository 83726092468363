import { useSelector } from 'react-redux'
import * as S from './styles'
import { getLoja } from '../../shared/redux/selectors';
import { BsFillCaretDownFill, BsFillPersonFill, BsPower } from 'react-icons/bs';
import { Cores } from '../../shared/utils/styles';
import { Dropdown, MenuProps, Space } from 'antd';
import Cookies from 'js-cookie';

export const HeaderAdmin = () => {
    const loja = useSelector(getLoja);

    const items: MenuProps['items'] = [
        {
          key: '1',
          label: (
            <div onClick={() => {
              Cookies.remove('auth');
              Cookies.remove('usuario');
              Cookies.remove('senha');
            }}>
              <a style={{color: "red"}} rel="noopener noreferrer" href="/">
                Sair
              </a>
            </div>
          ),
          icon: <BsPower size={20} color='red' />,
        }
      ];
      
    return (
        <S.Container>
            <a href='/admin'>{loja?.nome_empresa}</a>
            <Dropdown overlayStyle={{zIndex: 9999999}} menu={{ items }}>
                <a onClick={(e) => e.preventDefault()}>
                <Space>
                    <BsFillPersonFill size={25} color={Cores.BRANCO} />
                    <BsFillCaretDownFill size={15} color={Cores.BRANCO} />
                </Space>
                </a>
            </Dropdown>
        </S.Container>
    )
}