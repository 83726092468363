import styled from 'styled-components';
import { Cores } from '../../../shared/utils/styles';
import backgroundLoja from '../../../assets/images/background.png'
import backgroundLojaMobile from '../../../assets/images/backgroundDestaqueMobile.png'

export const Container = styled.div`
    background: url(${backgroundLoja});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    padding-bottom: 10px;
    @media (max-width: 991px) {
        background: url(${backgroundLojaMobile});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
    }
`

export const tituloDestaque = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    padding-top: 10px;
    margin-bottom: 30px;
    >h2{
        margin-top:20px;
        font-size:17px;
        color:${Cores.BRANCO};
        text-align:center;
        font-weight:600;
    }
    div {
        height: 1px;
        background: linear-gradient(to right, #CD8A49 0%, #F8E58F 100%);
        border-radius: 5px;
        width: 170px;
    }
`
export const Cards = styled.div`
    display:flex;
    justify-content:space-evenly;
    flex-wrap:wrap;
    margin:auto;
`
export const botaoEstoque = styled.div`
    margin:40px 0px;
    text-align: center;
    display:flex;
    justify-content:center;
    >a{
        background: linear-gradient(269.65deg, #CF8E4D 66.67%, #F6E18B 100%);
        text-decoration:none;
        border-radius: 100px;
        padding: 15px 60px;
        font-weight: 600;
        font-size: 17px;
        color: white;
        :hover{
            transition:0.2s all;
            opacity:0.8;
        }
        @media (max-width: 1200px) {
            width:300px !important;
        }
    }
`