import styled from "styled-components";
import { Cores } from "../../../shared/utils/styles";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: ${Cores.BRANCO};
    
    .modal {
        color: ${Cores.PRETO};
    }
`
export const ContainerDeskTop = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;

    i {
        color: #CE8C4B;
    }
    .ant-select-selector {
        border-radius: 100px !important;
        background-color: black !important;
        border: 1px solid #393737 !important;
        color: white;
        font-family: Montserrat !important;
        font-size: 15px;
        height: 45px !important;
        display: flex;  
        padding: 0 20px !important;
        align-items: center;
    }
    .ant-select {
        width: calc(80vw / 3) !important;
    }
    .ant-space {
        display: flex;
        justify-content: flex-start;
    }
    .ant-checkbox-wrapper {
        margin-inline-start: 0;
        color: ${Cores.BRANCO};
    }
    @media (max-width: 991px) {
        .custom-select {
        min-width: 100% !important;
    }
    }
    .button {
        background: linear-gradient(273.46deg, #CE8C4B 60.93%, #F8E68F 96.8%);
        border-radius: 100px;
        color: white;
        border: none;
        padding: 22px 0;
        display: flex;
        font-size: 15px;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        font-family: Montserrat;
        width: 183px;
    }
    @media (max-width: 991px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .ant-select {
            width: 100% !important;
        }
        .button {
            width: 100%;
        }
    }
`