import styled from "styled-components"
import { Cores } from '../../shared/utils/styles';

export const Container = styled.div`
    margin-top: -20px;
    display: flex;
    padding-bottom: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .carrossel {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    i  {
        color: #DEAF65;
    }
    .title {
        color: ${Cores.BRANCO};
        text-align: center;
        margin-top: 50px;
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 16px;
    }
    .hr {
        height: 1px;
        background: linear-gradient(to right, #CD8A49 0%, #F8E58F 100%);
        border-radius: 5px;
        width: 140px;
        align-items: center;
        margin-bottom: 30px;
    }
`

export const DivCarrosel = styled.div`
    display: flex;
    margin: auto;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
`
export const iconsDepoimentos = styled.div`
    cursor: pointer;
    padding:3px;
    transition: 0.3s all;
    :hover{
        scale: 1.3;
    }
` 