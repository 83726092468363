import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    @media (max-width: 991px) {
        margin-top: 30px;
    }
    h2 {
        color: white;
        font-weight: 500;
        font-size: 17px;
    }
    .hr {
        margin-top: -8px;
        margin-bottom: 20px;
        width: 150px;
        height: 1px;
        background: linear-gradient(to right, #CD8A49 0%, #F8E58F 100%);
        border-radius: 5px;
    }
`
export const Cards = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 994px) {
        justify-content: space-evenly;
    }
`