import * as S from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getEstoque as getEstoqueSelector } from "../../../shared/redux/selectors";
import { Input } from "antd";
import { useState } from "react";
import { AppDispatch } from "../../../App";
import { populateInput } from "../../../shared/redux/slices/litoralEstoque";
import { useNavigate } from "react-router-dom";

export const Busca = () => {
  const estoque = useSelector(getEstoqueSelector);
  const [text, setText] = useState('')
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  let modelo = estoque.map((veiculo) => {
    return veiculo.modelo;
  });
  modelo = modelo.filter((item, index) => modelo.indexOf(item) === index);

  return (
    <S.Container>
      <Input
      suffix={<i onClick={() => {
        navigate(`/estoque?busca=${text.toLocaleLowerCase()}`)
            dispatch(populateInput())
            navigate('/estoque')
      }} className="ri-search-line ri-lg" />}
        className="input-buscar"
        onChange={(event) => {setText(event.target.value)}}
        style={{ width: 500, fontWeight: "bold" }}
        placeholder="Busque por modelo ou modelo"
        bordered={true}
        onKeyDown={(event) => {
          if(event.key === 'Enter') {
            dispatch(populateInput())
            navigate(`/estoque?busca=${text.toLocaleLowerCase()}`)
          }
        }}
      />
    </S.Container>
  );
};