import styled from 'styled-components';
import { Cores } from '../../../shared/utils/styles';

export const Container = styled.div`
`

export const Banner = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    background-color: ${Cores.PRETO};
    position: relative;
    video {
        opacity: 40%;
        object-fit: cover;
    }
    @media (min-width: 991px) {
        video {
            max-height: 100vh;
        }
    }
    .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    }

    @keyframes bounce {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0);
    }
    }
    .jump {
        color: #CE8C4B;
        margin-bottom: 10px;
        animation: bounce 1s infinite;
        margin-top: -52px;
    }
`