import styled from 'styled-components'
import { Cores } from '../../../shared/utils/styles'

export const Container = styled.div`
    margin: 40px 0px;
    color: ${Cores.BRANCO};
    width: 100%;

    >div{
        display: flex;
        align-items: center;
    }
    i {
        color: #DEAF65;
    }
    @media (max-width: 991px) {
        margin: 40px 0;
    }
`
export const DivCarrosel = styled.div`
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
`
export const iconsDepoimentos = styled.div`
    cursor: pointer;
    padding:0px;
    transition: 0.3s all;
    :hover{
        scale: 1.3;
    }
` 