import { Routes as RoutesDOM, Route, Navigate } from "react-router-dom";
import { Avaliacao } from "../Pages/Avaliacao";
import { Contato } from "../Pages/Contato";
import { Estoque } from "../Pages/Estoque";
import Home from "../Pages/Home";
import { PosVenda } from "../Pages/PosVenda";
import { SobreNos } from "../Pages/SobreNos";
import { Veiculo } from "../Pages/Veiculo";
import { Admin } from "../Pages/Admin";
import { Visitas } from "../Pages/Admin/Visitas";
import { Banner } from "../Pages/Admin/Banner";
import { Configuracoes } from "../Pages/Admin/Configuracoes";
import { Funcionarios } from "../Pages/Admin/Funcionarios";
import { Video } from "../Pages/Admin/Video";
import { WhatsApp } from "../Pages/Admin/Whatsapp";
import { Login } from "../Pages/Admin/Login";
import Cookies from "js-cookie";
import { Emails } from "../Pages/Admin/Emails";

const Routes = () => {
  const login = Cookies.get('auth');
  
  return (
    <RoutesDOM>
      {/* <Route path="/error" element={<ErrorPage />} />
      <Route path="*" element={<PageNotFound />} /> */}
      <Route path="/" element={<Home />}/>
      <Route path="/sobre-nos" element={<SobreNos />}/>
      <Route path="/contato" element={<Contato />}/>
      <Route path="/pos-venda" element={<PosVenda />}/>
      <Route path="/avaliacao" element={<Avaliacao />}/>
      <Route path="/estoque" element={<Estoque />}/>
      <Route path="/veiculo" element={<Veiculo />}/>

      <Route path="/admin/" element={<Login />}/>
      <Route path="/admin/home" element={<Admin />}/>
      <Route path="/admin/visitas" element={login ? <Visitas /> : <Navigate to="/admin/" />}/>
      <Route path="/admin/banner" element={login ? <Banner /> : <Navigate to="/admin/" />}/>
      <Route path="/admin/configuracoes" element={login ? <Configuracoes /> : <Navigate to="/admin/" />}/>
      <Route path="/admin/funcionarios" element={login ? <Funcionarios /> : <Navigate to="/admin/" />}/>
      <Route path="/admin/video" element={login ? <Video /> : <Navigate to="/admin/" />}/>
      <Route path="/admin/whatsapp" element={login ? <WhatsApp /> : <Navigate to="/admin/" />}/>
      <Route path="/admin/emails" element={login ? <Emails /> : <Navigate to="/admin/" />}/>
    </RoutesDOM>
  );
};

export default Routes;
