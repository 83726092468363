import { BsGraphUpArrow } from 'react-icons/bs'
import { AsideAdmin } from '../../../components/AsideAdmin'
import { HeaderAdmin } from '../../../components/HeaderAdmin'
import * as S from './styles'
import { AiOutlineUser } from 'react-icons/ai'
import { Cores } from '../../../shared/utils/styles'
import { Line } from 'react-chartjs-2'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../App'
import { useEffect, useState } from 'react'
import { getAcessos } from '../../../shared/redux/selectors'
import { getAcessosAnos, getAcessosDias, getAcessosMeses } from '../../../shared/redux/slices/litoralAdmin'
import { getLoja } from '../../../shared/redux/slices/litoralLoja'

export const Visitas = () => {
    const dispatch = useDispatch<AppDispatch>()
    const [labelChartDias, setLabelChartDias] = useState<any>([])
    const [dataChartDias, setDataChartDias] = useState<any>([])
    const [labelChartMeses, setLabelChartMeses] = useState<any>([])
    const [dataChartMeses, setDataChartMeses] = useState<any>([])
    const [labelChartAnos, setLabelChartAnos] = useState<any>([])
    const [dataChartAnos, setDataChartAnos] = useState<any>([])
    const acessos = useSelector(getAcessos)

    useEffect(()=> {
        dispatch(getAcessosDias())
        dispatch(getAcessosMeses())
        dispatch(getAcessosAnos())
        dispatch(getLoja())
    },[])

    useEffect(() => {
        const listDias = acessos.dia?.slice().reverse()
        const labelFilterDias = listDias?.map(item => item.dia)
        setLabelChartDias(labelFilterDias);
        const dataFilterDias = listDias?.map(item => item.acessos)
        setDataChartDias(dataFilterDias);

        const listMeses = acessos.mes?.slice().reverse()
        const labelFilterMeses = listMeses?.map(item => item.mes)
        setLabelChartMeses(labelFilterMeses);
        const dataFilterMeses = listMeses?.map(item => item.acessos)
        setDataChartMeses(dataFilterMeses);

        const listAnos = acessos.ano?.slice().reverse()
        const labelFilterAnos = listAnos?.map(item => item.ano)
        setLabelChartAnos(labelFilterAnos);
        const dataFilterAnos = listAnos?.map(item => item.acessos)
        setDataChartAnos(dataFilterAnos);
    },[acessos])

    const dataDias = {
        labels: labelChartDias,
        datasets: [
          {
            label: "Acessos",
            data: dataChartDias,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1
          }
        ]
      };
    const dataMeses = {
        labels: labelChartMeses,
        datasets: [
          {
            label: "Acessos",
            data: dataChartMeses,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1
          }
        ]
      };
    const dataAnos = {
        labels: labelChartAnos,
        datasets: [
          {
            label: "Acessos",
            data: dataChartAnos,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1
          }
        ]
      };

    return (
        <S.Container>
            <AsideAdmin />
            <HeaderAdmin />
            <div className='title'>
                <BsGraphUpArrow size={40} />
                <h1>Visitas</h1>
            </div>
            <div className='title-bar'>
                <AiOutlineUser color={Cores.BRANCO} size={35} />
                <h3>Visitas dos últimos dias</h3>
            </div>
            <div className='inside-bar'>
                <Line style={{maxHeight: 200}} data={dataDias} />
            </div>
            <div className='title-bar'>
                <AiOutlineUser color={Cores.BRANCO} size={35} />
                <h3>Visitas dos últimos meses</h3>
            </div>
            <div className='inside-bar'>
                <Line style={{maxHeight: 200}} data={dataMeses}/>
            </div>
            <div className='title-bar'>
                <AiOutlineUser color={Cores.BRANCO} size={35} />
                <h3>Visitas dos últimos anos</h3>
            </div>
            <div className='inside-bar'>
                <Line style={{maxHeight: 200}} data={dataAnos}/>
            </div>
        </S.Container>
    )
}