import styled from "styled-components";

export const Container = styled.div`
    max-width: fit-content;
    min-width: 65%;
    margin: 30px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 15px;

    img {
        border-radius: 0 15px 15px 0;
        height: -webkit-fill-available;
        object-fit: cover;
        width:100%;
    }
    .form {
        padding: 50px;
        background-color: #141414;
        width: 100%;
        border-radius: 15px 0 0 15px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    .ant-form-item {
        width: 100%;
        margin-bottom: 18px;
    }
    #posVenda_contato, #posVenda_dataCompra {
        width: 100%;
        border-radius: 5px;
        height: 42px;
        outline: none;
        border: none;
        background-color: transparent;
        color: white;
        border: 1px solid #393737;
        color: white;
        font-weight: 500;
        padding: 0 5px;
        font-size: 14px;
        font-family: Montserrat;
        ::placeholder {
            color: #7B7B7B;
            padding: 0 9px;
        }
    }
    h2 {
        font-weight: 700;
        color: white;
        font-size: 30px;
    }
    .titulo-form {
        width: 100%;
        display: flex;
        float: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    }
    .yellow {
        color: #CE8C4B;
    }
    .ant-row {
        width: 100%;
    }
    .ant-form-item-margin-offset {
        display: none;
    }
    .ant-input {
        border: 1px solid #393737;
        background-color: transparent;
        color: white;
        font-family: Montserrat;
        font-size: 12px;
        font-size: 15px;
        padding: 8px 16px;
        ::placeholder {
            color: #828282;
            font-size: 15px;
        }
    }
    button{
        height: 40px;
        width: 100%;
        margin: 10px auto;
        font-weight: 600;
        transition: 0.4s all;
        cursor: pointer;
        background: linear-gradient(269.62deg, #CE8C4B 47.28%, #F8E68F 100%);
        border-radius: 20px;
        color: white;
        outline: none;
        border: none;
        font-family: Montserrat;
        :hover{
            opacity: 0.7;
        }     
    }
    @media (max-width: 991px) {
        max-width: 100%;
        padding: 0;
        margin: 0 15px 15px;
        grid-template-columns: 1fr;
        margin-top: 30px;

        h2 {
            font-size: 23px !important;
        }
        .titulo-form {
            justify-content: start;
        }
        .form {
            padding: 25px 15px;
            border-radius: 15px;
        }
    }
`
