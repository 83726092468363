import imgCoracao from "../../assets/images/InformacoesLoja/coracao.png"
import imgCarro from "../../assets/images/InformacoesLoja/carro.png"
import imgSeguro from "../../assets/images/InformacoesLoja/seguro.png"
import imgPessoas from "../../assets/images/InformacoesLoja/pessoas.png"

//depoimentos
import Albertogoogle from '../../assets/images/Depoimentos/Albertogoogle.png'
import Anagoogle from '../../assets/images/Depoimentos/Anagoogle.png'
import Catarinagoogle from '../../assets/images/Depoimentos/Catarinagoogle.png'
import Ednagoogle from '../../assets/images/Depoimentos/Ednagoogle.png'
import Eduardogoogle from '../../assets/images/Depoimentos/Eduardogoogle.png'
import Gersongoogle from '../../assets/images/Depoimentos/Gersongoogle.png'
import Renatogoogle from '../../assets/images/Depoimentos/Renatogoogle.png'
import Sannygoogle from '../../assets/images/Depoimentos/Sannygoogle.png'
import Williangoogle from '../../assets/images/Depoimentos/Williangoogle.png'
import unnamed from '../../assets/images/Depoimentos/unnamed.png'


const dataAtual = new Date();
const anoAtual = dataAtual.getFullYear();
const anosNoMercado = anoAtual - 2009;
export const Cards = [
    {
        id: 1,
        img: imgCoracao,
        texto: `Há mais de ${anosNoMercado} anos no mercado`,
    },
    {
        id: 2,
        img: imgCarro,
        texto: "+ 60 veículos em estoque",
    },
    {
        id: 3,
        img: imgSeguro,
        texto: "Facilidade nas entregas e melhores taxas",
    },
    {
        id: 4,
        img: imgPessoas,
        texto: "Veículos revisados com pós venda",
    },
];

export const DepoimentosJson = [
    {
        "id": 1,
        "depoimento": "Negociação justa, transparente, com atendimento especializado e cordial por toda equipe MC Veículos.",
        "nome": "Gerson André Bernardo De Oliveira",
        "tempo": "Uma semana atrás",
        "img": Gersongoogle
    },
    {
        "id": 2,
        "depoimento": "Foi ótimo fazer negócios com o Tita, sempre atencioso e tentando resolver da melhor forma. Recomendo e volto a comprar sem dúvidas.",
        "nome": "Willian Costa",
        "tempo": "2 meses atrás",
        "img": Williangoogle
    },
    {
        "id": 3,
        "depoimento": "Muito bom atendimento, passando segurança para comprar o veículo. Além disso vistoria cautelar e todo histórico do carro, recomendo! Meu muito obrigado ao Availton pela atenção concedida e por agilizar os procedimentos necessários.",
        "nome": "Bruno Corrêa",
        "tempo": "3 semanas atrás",
        "img": unnamed
    },
    {
        "id": 4,
        "depoimento": "Atendimento excelente, passam muita confiança e credibilidade vendem produtos de qualidade e procedência. Muito bom!",
        "nome": "Eduardo Silva",
        "tempo": "4 meses atrás",
        "img": Eduardogoogle
    },
    {
        "id": 5,
        "depoimento": "Super recomendo ,comprei meu carro com vendedor Luciano foi muito atencioso ,gerente Tita tá de parabéns sai muito feliz .",
        "nome": "Catarina Nunes Duarte",
        "tempo": "2 meses atrás",
        "img": Catarinagoogle

    },
    {
        "id": 6,
        "depoimento": "Vendedor Availton, muito atencioso com atendimento top! Fez tudo pra facilitar a compra! Obrigada",
        "nome": "Ana Horst Prado",
        "tempo": "Uma semana atrás",
        "img": Anagoogle
    },
    {
        "id": 7,
        "depoimento": "Fui atendido pelo Tita, fez toda a diferença!! Atencioso, honesto no que fala, fantástico!! Recomendadíssimo! MC veículos, pode confiar!!",
        "nome": "Alberto César Schell de Moraes",
        "tempo": "3 meses atrás",
        "img": Albertogoogle
    },
    {
        "id": 8,
        "depoimento": "Loja extremamente atenciosa e parceira. Avaliação muito boa no meu semi novo, taxas e o atendimento mais rápido que já recebi. Assino em baixo ! Loja toppp",
        "nome": "Renato Prochmann",
        "tempo": "3 meses atrás",
        "img": Renatogoogle
    },
    {
        "id": 9,
        "depoimento": "Estamos muito satisfeito com o atendimento, prestativo atencioso. Disposto a ajudar da melhor forma possível o cliente, ponto muito positivo a transparência na negociação. Gratidão.",
        "nome": "Sanny Tambosi",
        "tempo": "4 meses atrás",
        "img": Sannygoogle
    },
    {
        "id": 10,
        "depoimento": "Nota 10 no atendimento do vendedor João e gerente Tita, quando da compra que fiz do Fiat 500 cabriolet...nada a reclamar, só elogios...extensivo ao gentil proprietário que simpaticamente ofereceu salgadinhos com café e refrigerante quando do fechamento da compra... obrigada, abs",
        "nome": "Edna Di Bernardi De Assis",
        "tempo": "5 meses atrás",
        "img": Ednagoogle
    }
];

export const IframeObj = [
    {
        id : 1,
        endereco : "Rua Álvaro Tolentino, 1828 - 88080170 Capoeiras - Florianópolis, SC",
        link : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1767.853509057407!2d-48.59059196890749!3d-27.60261157437393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x952737c889c1e3e5%3A0xf905edb55949cd08!2sR.%20%C3%81lvaro%20Tolentino%2C%201828%20-%20Capoeiras%2C%20Florian%C3%B3polis%20-%20SC%2C%2088080-170!5e0!3m2!1spt-BR!2sbr!4v1674654001814!5m2!1spt-BR!2sbr'
    },
    {
        id : 2,
        endereco : "R. Júlio Müler, 727 - 88110-090 Barreiros - São José - SC",
        link : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1250.4154087874308!2d-48.613303749952934!3d-27.571544720836005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x952749b938330785%3A0x2f47ae71645e2e43!2zUi4gSsO6bGlvIE3DvGxlciwgNzI3IC0gQmFycmVpcm9zLCBTw6NvIEpvc8OpIC0gU0MsIDg4MTEwLTA5MA!5e0!3m2!1spt-BR!2sbr!4v1674585995028!5m2!1spt-BR!2sbr'
    }
]
