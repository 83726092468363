import { BsCardImage, BsList, BsPencilSquare, BsTrash3 } from 'react-icons/bs'
import { AsideAdmin } from '../../../components/AsideAdmin'
import { HeaderAdmin } from '../../../components/HeaderAdmin'
import * as S from './styles'
import { Button, Checkbox, Col, Dropdown, Input, Menu, Modal, Row } from 'antd'
import { useEffect, useState } from 'react'
import { getBanner } from '../../../shared/redux/slices/litoralAdmin'
import { getBanner as getBannerSelector } from '../../../shared/redux/selectors/'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../App'
import { Cores } from '../../../shared/utils/styles'
import { deleteBanner, editBanner, postBanner } from '../../../service/apiLitoral'
import { toast } from 'react-toastify'

export const Banner = () => {
    // TODO Limpar campos dps de salvar sem reload
    const DateFunc = (props: string) => {
        const date = new Date(props)
        const day = date.getDate().toString().padStart(2, '0'); // obtém o dia com zero à esquerda se necessário
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // obtém o mês com zero à esquerda se necessário (lembre-se que os meses em JavaScript são baseados em zero, então adicione 1)
        const year = date.getFullYear(); // obtém o ano com quatro dígitos

        return `${day}/${month}/${year}`
    }
    const dispatch = useDispatch<AppDispatch>()
    const banner = useSelector(getBannerSelector)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalEditar, setIsModalEditarOpen] = useState(false);
    const [situacao, setSituacao] = useState(true);
    const [bannerEditObj, setBannerEditObj] = useState<any>(null);
    const [file, setFile] = useState<any>('');
    const [imgUrl, setImgUrl] = useState<string>('');
    const [titulo, setTitulo] = useState<string>('');
    const [dateInicio, setDateInicio] = useState<string>('');
    const [dateFim, setDateFim] = useState<string>('');
    const [mobile, setMobile] = useState<boolean>(false);

    useEffect(()=>{
        dispatch(getBanner())
    },[])

    const menuItems: any = [
        {
          key: 'editar',
          label: (
              <span style={{display: "flex", alignItems: "center", gap: 10}}>
                <BsPencilSquare size={20} />
                <b>Editar</b>
              </span>
          ),
        },
        {
          key: 'deletar',
          label: (
            <span style={{display: "flex", alignItems: "center", gap: 10}}>
              <BsTrash3 size={20} />
              <b>Deletar</b>
            </span>
        ),
          danger: true,
        },
      ];

    useEffect(() => {
        setSituacao(bannerEditObj?.situacao)
    },[bannerEditObj])
    const onFinish = async () => {
        if(!isModalEditar) {
            if(!file || !titulo || !dateInicio || !dateFim) {
                toast.error("Preencha todos os campos!")
                return
            }
        }
        
        if(isModalEditar) {
            const data = {
                arquivo: file ? file : bannerEditObj?.arquivo,
                titulo: titulo ? titulo : bannerEditObj.titulo,
                url: '/',
                situacao: situacao === false ? "false" : "true",
                isMobile: bannerEditObj.isMobile === 1 ? "true" : "false",
                data_inicio: dateInicio ? dateInicio : bannerEditObj.data_inicio,
                data_fim: dateFim ? dateFim : bannerEditObj.data_fim,
                posicao: banner ? (banner.length + 1) : 0,
                id: bannerEditObj.id
            }
            await editBanner({
                data: data
            })
            toast.success("Banner atualizado com sucesso!")
            window.location.reload()
        } else {
            const formData = new FormData();
            formData.append('arquivo', file);
            formData.append('titulo', titulo);
            formData.append('url', "/");
            formData.append('situacao', situacao === false ? 'false' : 'true');
            formData.append('data_inicio',  dateInicio);
            formData.append('data_fim',  dateFim);
            formData.append('isMobile',  mobile ? "true" : "false");
            formData.append('posicao',  banner ? (banner.length + 1).toString() : '0');
            await postBanner(formData)
            toast.success("Banner cadastrado com sucesso!")
            window.location.reload()
        }
      };

      const setSituacaoButton = () => {
        setSituacao(!situacao)
      };
      const setImageInput = (value: any) => {
        const file = value.target.files[0];
        setFile(file);
        const url = URL.createObjectURL(file);
        setImgUrl(url);
      };

    return (
        <S.Container>
            <Modal footer={null} width={1000} title={isModalEditar ? "Editar banner" : "Cadastrar Banner"} open={isModalOpen || isModalEditar} onCancel={() => {setIsModalOpen(false); setIsModalEditarOpen(false); window.location.reload()}}>
                <p>Tamanho ideal para imagem(desktop): <b>1920px de largura por 600px de altura.</b></p>
                <p>Tamanho ideal para imagem(mobile): <b>400px de largura por 650px de altura.</b></p>
                <div>
                    <div style={{display: 'flex', flexDirection: "row", gap: 10, justifyContent: "space-around", marginTop: 20}}>
                        <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                            <div>
                                <h5>Título:</h5>
                                <Input id="titulo" onChange={(event) => {setTitulo(event.target.value)}} required defaultValue={isModalEditar ? bannerEditObj.titulo : titulo} />
                            </div>

                        <div style={{display: "flex", flexDirection: "row", gap:10 , justifyContent: "space-between"}}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <h5>Data início</h5>
                                <Input id="inicio" required type="date" onChange={(event) => setDateInicio(event.target.value)} defaultValue={bannerEditObj?.data_inicio?.substr(0, 10)} />
                            </div>
                            <div style={{display: "flex", flexDirection: "column"}}>
                            <h5>Data final</h5>
                                <Input id="final" required type="date" onChange={(event) => setDateFim(event.target.value)} defaultValue={bannerEditObj?.data_fim?.substr(0, 10)} />
                                </div>
                                <div style={{display: "flex", flexDirection: "column"}}>
                            <h5>Situação</h5>
                                <Button id="situacao" type="primary" danger={situacao?.toString() === "false" ? true : false} onClick={setSituacaoButton}>{situacao?.toString() === "false" ? "Inativo" : "Ativo"}</Button>
                                </div>
                        </div>
                        <div>
                            {isModalEditar ? null : (
                                <>
                                    <h5>Selecionar foto</h5>
                                    <div style={{display: 'flex', flexDirection: "row", gap: "15px", justifyContent: "center", alignItems: "center"}}>
                                        <Input id="foto" defaultValue={isModalEditar ? bannerEditObj?.arquivo : file} required onChange={setImageInput} type='file' />
                                        <h5>Mobile</h5>
                                        <Checkbox id="mobile" onChange={(event) => {setMobile(event.target.checked)}} />
                                    </div>
                                </>
                            )}
                        </div>
                        <Button id="salvar" style={{backgroundColor: Cores.VERDE_600}} type="primary" onClick={onFinish}>
                            Salvar
                        </Button>
                        </div>
                        {imgUrl || bannerEditObj ? (<img style={{maxWidth: 400, height: "auto"}} src={imgUrl ? imgUrl : `${process.env.REACT_APP_API_FOTOS_VIDEOS}/fotos/${bannerEditObj?.arquivo}`} alt="" />): (
                            <p>Sua imagem aparecerá aqui</p>
                        )}
                    </div>
                </div>
            </Modal>
            <AsideAdmin />
            <HeaderAdmin />
            <div className='title'>
                <span>
                    <BsCardImage size={40} />
                    <h1>Banner</h1>
                </span>
                <Button onClick={() => {setIsModalOpen(true); setTitulo(''); setSituacao(true)}} style={{maxWidth: 180, backgroundColor: Cores.VERDE_600}} type="primary">Cadastrar novo banner</Button>
            </div>
            <h2>Banners cadastrados</h2>
            <div>
                <Row className='title-table'>
                    <Col span={5}>
                        <h4>Foto</h4>
                    </Col>
                    <Col span={7}>
                        <h4>Nome</h4>
                    </Col>
                    <Col span={4}>
                        <h4>Data Início</h4>
                    </Col>
                    <Col span={5}>
                        <h4>Data Final</h4>
                    </Col>
                    <Col span={1}>
                        <h4>Ações</h4>
                    </Col>
                </Row>
                {banner?.map((x) => (
                <Row className='title-table'>
                    <Col span={5}>
                        <img style={{maxWidth: 150}} src={`${process.env.REACT_APP_API_FOTOS_VIDEOS}/fotos/${x.arquivo}`} alt={x.titulo} />
                    </Col>
                    <Col span={7}>
                        <h5>{x.titulo}</h5>
                    </Col>
                    <Col span={4}>
                        <h5>{DateFunc(x.data_inicio)}</h5>
                    </Col>
                    <Col span={5}>
                        <h5>{DateFunc(x.data_fim)}</h5>
                    </Col>
                    <Col span={1}>
                    <Dropdown placement="bottomLeft"  overlay={
                        <Menu>
                            {menuItems.map((item: any) => (
                            <Menu.Item onClick={async () => {
                                const funcEdit = banner.find(m => m.id === x.id)
                                if(item?.danger) {
                                    await deleteBanner(x.id.toString())
                                    dispatch(getBanner())
                                } else {
                                    setIsModalEditarOpen(true);
                                    setBannerEditObj(funcEdit)
                                }
                            }} key={item?.key} danger={item?.danger}>
                                {item.icon} {item.label}
                            </Menu.Item>
                            ))}
                        </Menu>
                        }>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <BsList size={25} color={Cores.PRETO} />
                        </a>
                        </Dropdown>
                    </Col>
                </Row>
                ))}
            </div>
        </S.Container>
    )
}