import * as S from './styles'
import React from 'react';
import CardVeiculo from '../../../components/CardVeiculo';
import { useSelector } from 'react-redux';
import { getEstoque as getEstoqueSelector, getLoja as getLojaSelector } from '../../../shared/redux/selectors';
import { Veiculos as VeiculosDictionary } from '../../../shared/utils/dictionary';
import { Loja as LojaDictionary } from '../../../shared/utils/dictionary';
import { isMobile } from 'react-device-detect';
export const Destaque: React.FC = () => {
    const loja = useSelector(getLojaSelector)
    
    const estoque = useSelector(getEstoqueSelector)
    return (
        <S.Container>
            <S.tituloDestaque>
                <h2>CONHEÇA NOSSO ESTOQUE</h2>
                <div />
            </S.tituloDestaque>
            <S.Cards>
                {estoque?.slice(0, 6).map((veiculo) => {
                    return <CardVeiculo key={veiculo[VeiculosDictionary.CODIGO_VEICULO]} codLoja={loja?.[LojaDictionary.CODIGO_LOJA]} veiculo={veiculo} />
                })}
            </S.Cards>
            <S.botaoEstoque>
                <a href="/estoque">
                    {isMobile ? "ESTOQUE COMPLETO" : "NOSSO ESTOQUE COMPLETO"}
                </a>
            </S.botaoEstoque>
        </S.Container>
    )
}
