import * as S from './styles'
import React, { useEffect } from 'react';
import { Header as HeaderComponent } from '../../../components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../App';
import { getSlide} from '../../../shared/redux/slices/litoralLoja';
import Seta from '../../../assets/images/seta.png'
import { getSlice as getSlideSelector } from '../../../shared/redux/selectors';
import { isMobile } from 'react-device-detect';
import { Busca } from '../Buscar';

declare global {
  interface HTMLElement {
    getBoundingClientRect: () => DOMRect;
  }
}

const Header: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const banner = useSelector(getSlideSelector)

  useEffect(()=>{
    dispatch(getSlide())
  },[])

  const findBanner = (isMobile: boolean) => {
    const findBannerFoto = banner?.filter((x: { tipo: string; }) => x.tipo === "BANNER")
    const findBanner = findBannerFoto?.find((x: { isMobile: boolean; }) => x.isMobile === isMobile)

    let src = `${process.env.REACT_APP_API_FOTOS_VIDEOS}/fotos/${findBanner?.arquivo}`
    if(!findBanner) {
      src = `${process.env.REACT_APP_API_FOTOS_VIDEOS}/fotos/${findBanner ? findBanner?.arquivo : findBannerFoto[0].arquivo}`
    }
    return src
  }

  const findVideo = (isMobile: boolean) => {
    const findBannerVideo = banner?.filter((x: { tipo: string; }) => x.tipo === "VIDEO")
    const findBanner = findBannerVideo?.find((x: { isMobile: boolean; }) => x.isMobile === isMobile)

    let src = `${process.env.REACT_APP_API_FOTOS_VIDEOS}/videos/${findBanner?.arquivo}`
    if(navigator.platform.includes('Mac')) {
      return src
    }
    if(!findBanner) {
      src = `${process.env.REACT_APP_API_FOTOS_VIDEOS}/videos/${banner?.[0]?.arquivo}`
    }
    return src
  }

  return (
    <S.Container>
      <HeaderComponent titulo='Início' />
            <S.Banner>
            {banner?.[0]?.tipo === "BANNER" ? (
                  <img style={{width: "100%"}} src={findBanner(isMobile ? true : false)} alt="" /> 
                 ) : (
                  <div style={{width: '100%', position: 'relative', overflow: "hidden"}}>
                    <video width="100%" muted autoPlay loop preload="auto" playsInline src={findVideo(isMobile ? true : false)}></video>
                    <div className="gradient-overlay" style={{position: 'absolute',
                      top: '30%',
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9))'}}>
                    </div>
                  </div>
                )}
              {isMobile ? <img className='jump' src={Seta}></img> : (
                <Busca />
                )}
            </S.Banner>
                
      </S.Container>
  )
}

export default Header;
