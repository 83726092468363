import * as S from "./styles";

import { CardDepoimentos } from "./CardDepoimento";
import { useState } from "react";
import { Depoimentos as DepoimentosDictionary} from "../../../shared/utils/dictionary";
import { DepoimentosJson } from "../../../shared/Constantes";
import { isMobile } from "react-device-detect";

export const Depoimentos = () => {

  const [index, setIndex] = useState([0,1,2,3])

  const incrementNumbers = () => {
      const lastNumber = index[index.length - 1];
      if (lastNumber === DepoimentosJson.length - 1) {
          setIndex([0, 1, 2, 3]);
      } else {
          const updatedNumbers = index.map((number) => number + 1);
          setIndex(updatedNumbers);
      }
    };
    
  const decrementNumbers = () => {
      const firtNumber = index[0];
      if (firtNumber === 0) {
          setIndex([DepoimentosJson.length - 4, DepoimentosJson.length - 3, DepoimentosJson.length - 2, DepoimentosJson.length - 1]);
      } else {
          const updatedNumbers = index.map((number) => number - 1);
          setIndex(updatedNumbers);
      }
    };


  return (
    <S.Container>
      {isMobile ? (
        <div>
           <S.iconsDepoimentos>
          <i onClick={decrementNumbers} className="ri-arrow-left-s-line ri-xl"></i>
        </S.iconsDepoimentos>
        <S.DivCarrosel>
              <CardDepoimentos key={DepoimentosJson[index[0]][DepoimentosDictionary.ID]} depoimento={DepoimentosJson[index[0]]} />
        </S.DivCarrosel>
        <S.iconsDepoimentos>
          <i onClick={incrementNumbers} className="ri-arrow-right-s-line ri-xl"></i>
        </S.iconsDepoimentos>
        </div>
      ) : (
      <div>
        <S.iconsDepoimentos>
          <i onClick={decrementNumbers} className="ri-arrow-left-s-line ri-xl"></i>
        </S.iconsDepoimentos>
        <S.DivCarrosel>
              <CardDepoimentos key={DepoimentosJson[index[0]][DepoimentosDictionary.ID]} depoimento={DepoimentosJson[index[0]]} />
              <CardDepoimentos key={DepoimentosJson[index[1]][DepoimentosDictionary.ID]} depoimento={DepoimentosJson[index[1]]} />
              <CardDepoimentos key={DepoimentosJson[index[2]][DepoimentosDictionary.ID]} depoimento={DepoimentosJson[index[2]]} />
              <CardDepoimentos key={DepoimentosJson[index[3]][DepoimentosDictionary.ID]} depoimento={DepoimentosJson[index[3]]} />
        </S.DivCarrosel>
        <S.iconsDepoimentos>
          <i onClick={incrementNumbers} className="ri-arrow-right-s-line ri-xl"></i>
        </S.iconsDepoimentos>
      </div>
      )}
      
    </S.Container>
  );
};
