import { RootState } from "../../../shared/types";

export const getEstoque = (state: RootState) =>
  state.litoralState.estoque;

export const getVeiculo = (state: RootState) =>
  state.litoralState.veiculo;

export const getLoja = (state: RootState) =>
  state.litoralLojaState.loja;

export const getInputs = (state: RootState) =>
  state.litoralState.inputs;

export const getEstoqueFiltrado = (state: RootState) =>
  state.litoralState.veiculosFiltrado;

export const getInfoLoja = (state: RootState) =>
  state.litoralAdminState.infoLoja;

export const getBanner = (state: RootState) =>
  state.litoralAdminState.banner;

export const getVideo = (state: RootState) =>
  state.litoralAdminState.video;

export const getFuncionarios = (state: RootState) =>
  state.litoralAdminState.funcionarios;

export const getAcessos = (state: RootState) =>
  state.litoralAdminState.acessos;

export const getWhats = (state: RootState) =>
  state.litoralAdminState.whats;

export const getSlice = (state: RootState) =>
  state.litoralLojaState.banner;

export const getEmailsAdmin = (state: RootState) =>
  state.litoralAdminState.emails;

