import styled from "styled-components";
import { Cores } from "../../shared/utils/styles";

export const Container = styled.div`
    padding: 80px 20px 20px 100px;
    background-color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .title {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
    }
    .title-bar {
        background-color: ${Cores.VERDE_600};
        display: flex;
        align-items: center;
        color: ${Cores.BRANCO};
        flex-direction: row;
        gap: 5px;
        border-radius: 10px;
        padding: 10px;
        h3 {
            font-weight: 300;
        }
    }
    .inside-bar {
        margin-top: -20px;
        padding: 20px 10px 15px 10px;
        border: 2px solid ${Cores.VERDE_600};
        border-radius: 0 0 10px 10px;
    }
`