import { FormContato } from './FormContato'
import { MapasContato } from './MapasContato'
import * as S from './styles'
import { TituloContato } from './TituloContato'
export const ConteudoContato = () => {
    return (
        <S.Container>
            <TituloContato/>
            <S.DivFormMapa>
                <FormContato/>
                <MapasContato/>
            </S.DivFormMapa>
        </S.Container>
    )
}