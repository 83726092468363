import styled from "styled-components"
import imagem from '../../assets/images/backgroundNossaHistoria.png'
import imagemMobile from '../../assets/images/mobileNossaHistoriaBackground.png'


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: url(${imagem});
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center top;
    width: 100%;
    background-color: #141414;

    .info-loja {
        display: flex;
        flex-direction: row;
        gap: 50px;
        padding: 25px 0;
    }
    .hr {
        height: 1px;
        background: linear-gradient(to right, #CD8A49 0%, #F8E58F 100%);
        border-radius: 5px;
        width: 140px;
        align-items: center;
        margin-bottom: 30px;
    }
    span {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0 35px;
        justify-content: center;
        align-items: center;
    }
    p {
        color: white;
        text-align: justify;
        font-weight: 300;
        font-family: Montserrat;
        font-size: 17px;
    }
    h1 {
        font-weight: 600;
        font-size: 17px;
        color: white;
    }
    img {
        max-width: calc(100vw / 2);
    }
    .imagem-carro {
        padding: 0 35px;
    }
    @media (max-width: 991px) {
        background: url(${imagemMobile});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
        .info-loja {
            flex-direction: column;
            gap: 15px;
        }
        img {
            max-width: 100%;
        }
        span {
            padding: 0 15px;
        }
        .imagem-carro {
        padding: 0 15px;
    }
    }
`