import { useDispatch, useSelector } from 'react-redux'
import { HeaderAdmin } from '../../components/HeaderAdmin'
import { Line } from "react-chartjs-2";
import * as S from './styles'
import { AppDispatch } from '../../App'
import { useEffect, useState } from 'react'
import { getLoja } from '../../shared/redux/slices/litoralLoja'
import { AsideAdmin } from '../../components/AsideAdmin'
import { AiFillDashboard, AiOutlineUser } from 'react-icons/ai'
import { Cores } from '../../shared/utils/styles'

import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title } from 'chart.js';
import { getAcessosDias } from '../../shared/redux/slices/litoralAdmin';
import { getAcessos } from '../../shared/redux/selectors';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title);

export const Admin = () => {
    const dispatch = useDispatch<AppDispatch>()
    const [labelChart, setLabelChart] = useState<any>([])
    const [dataChart, setDataChart] = useState<any>([])
    const acessos = useSelector(getAcessos)
    const login = Cookies.get('auth');
    const navigate = useNavigate()

    useEffect(()=> {
        if(!login) {
            navigate('/admin')
        }
        dispatch(getAcessosDias())
        dispatch(getLoja())
    },[dispatch, login, navigate])

    useEffect(() => {
        const list = acessos.dia?.slice().reverse()
        const labelFilter = list?.map(item => item.dia)
        setLabelChart(labelFilter);
        const dataFilter = list?.map(item => item.acessos)
        setDataChart(dataFilter);
    },[acessos])

    const data = {
        labels: labelChart,
        datasets: [
          {
            label: "Acessos",
            data: dataChart,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1
          }
        ]
      };

    return (
        <S.Container>

                    <AsideAdmin />
                    <HeaderAdmin />
                    <div className='title'>
                        <AiFillDashboard size={40} />
                        <h1>Painel</h1>
                    </div>
                    <div className='title-bar'>
                        <AiOutlineUser color={Cores.BRANCO} size={35} />
                        <h3>Visitas dos últimos dias</h3>
                    </div>
                    <div className='inside-bar'>
                        <Line style={{maxHeight: 200}} data={data}/>
                    </div>
        </S.Container>
    )
}