import styled from "styled-components";

export const Conatiner = styled.div`
    display: grid;
    grid-template-columns: 1fr 7fr;
    max-width: calc(90vw / 3);
    color: white;
    border-radius: 10px;
    border: 1px solid  #CE8C4B;
    max-height: 200px;
    word-wrap: break-word;

    .container-img {
        width: 150px;
        height: 150px;
    }
    img {
        max-width: 150px;
        height: 100%;
        border-radius: 10px 0 0 0;
        object-fit: cover;
    }
    h6 {
        font-weight: 400;
        max-width: 300px;
        text-transform: uppercase;
        margin-bottom: 6px;
    }
    .nome {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        border-radius: 0 0 0 10px;
    }
    h5 {
        margin-top: 8px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
    }
    .desc {
        padding: 10px;
        font-size: 14px;
        text-transform: none;
        font-weight: 300;
        max-height: 180px;
        overflow: auto;
        max-width: 100%;
        grid-row: span 2;
    }
    
   .desc::-webkit-scrollbar {
    width: 6px;
    }
    .desc::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
    }
    .desc::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #999999;
    }
    
    @media (max-width: 991px) {
        max-width: 100%;
    }
`
