/* eslint-disable jsx-a11y/anchor-is-valid */
import { BsWhatsapp } from "react-icons/bs";
import * as S from "./styles";
import { useEffect, useState } from "react";
import { getLoja as getLojaSelector } from '../../shared/redux/selectors';
import { useSelector } from "react-redux";
import { postWhats } from "../../service/apiLitoral";
import { isMobile } from 'react-device-detect';
import axios from "axios";

export const WhatsappFlutuante = () => {
  const loja = useSelector(getLojaSelector)
  const [ip, setIP] = useState()
  
  const getData = async () => {

    axios.get('https://api.ipify.org?format=json')
    .then(response => {
      setIP(response.data.ip);
    })
    .catch(error => {
      console.log(error);
    });
    
  };

  useEffect(()=>{
    getData()
  },[])

  return (
      <S.Container>
        <div onClick={async() => {
        await getData();
        if(!ip) return
        postWhats({
          ip: ip,
          dispositivo: isMobile ? 'MOBILE' : 'DESKTOP',
          lojaId: Number(process.env.REACT_APP_COD_LOJA),
          codVeiculo: 0
        })
      window.open(`https://api.whatsapp.com/send?phone=+55${loja.telefones ? loja.telefones[0].telefone.replace(/[( )-]/g,"") : "48 99177-3960".replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site da *${loja?.nome_empresa}* e gostaria de mais informações.`, '_blank')

      }}>
          <BsWhatsapp />
        </div>
      </S.Container>
  );
};
