import * as S from "./styles";
import { useSelector } from "react-redux";
import { getInfoLoja } from "../../shared/redux/selectors";
import imagemLoja from '../../assets/images/nossaHistoria.png'
import { InfosLoja } from "../../shared/utils/dictionary";
import { Depoimentos } from "../../Pages/Home/Depoimentos";
import { Funcionarios } from "../GridFuncionarios";

type TypeInfoLoja = {
  depoimentos: boolean
}

export const InformacoesLoja = ({depoimentos}: TypeInfoLoja) => {

  const loja = useSelector(getInfoLoja)
  function createMarkup(html: any) {
    return { __html: html };
  }

  return (
      <S.Container>
        <div className="info-loja">
          <span>
              <h1>A NOSSA HISTÓRIA</h1>
              <div className="hr" /> 
              <p dangerouslySetInnerHTML={createMarkup(loja[InfosLoja.DESCRICAO])} />
          </span>
          <img className="imagem-carro" src={imagemLoja} alt="Nossa história" />
        </div>
        {depoimentos ?  <Depoimentos /> : <Funcionarios />}
       
      </S.Container>
  );
};
