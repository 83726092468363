import * as S from "./styles";
import { useState, useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { Modal } from "antd";

const CookieButton = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function handleClickModal() {
    setModalIsOpen(!modalIsOpen);
  }

  const [showButton, setShowButton] = useState(false);

  const handleAcceptCookies = () => {
    localStorage.setItem("cookiesAccepted", true.toString());
    setShowButton(false);
  };

  const checkCookiesAccepted = () => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (!cookiesAccepted) {
      setShowButton(true);
    }
  };

  // Chama checkCookiesAccepted quando o componente for montado
  useEffect(() => {
    checkCookiesAccepted();

  }, []);
  // Renderiza o botão de cookies apenas se showButton for true

  if (showButton) {
    return (
      <S.Container>
        <div>
          <span>
            Aviso de Cookies Este site usa Cookies, pequenos arquivos para
            aprimorar e proteger a sua experiência :) Tá curtindo e quer
            continuar navegando? Saiba mais na nossa
          </span>
          <S.PoliticaDePrivacidade onClick={handleClickModal}>
            - <b>Politica de privacidade.</b>
          </S.PoliticaDePrivacidade>
          <Modal cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }} closable={false} open={modalIsOpen}>
            <S.PoliticaDePrivacidade>
              <div>
                <h5>Politica de privacidade e termos de uso</h5>

                <AiFillCloseCircle onClick={handleClickModal} />

              </div>
              <p>
                Termo de Consentimento para Tratamento de Dados Pessoais e
                Politicas Gerais de Utilização
                <br />
                Este documento visa registrar a manifestação livre, informada e
                inequívoca pela qual o Titular concorda com o tratamento de seus
                dados pessoais para finalidade específica, em conformidade com a
                Lei nº 13.709 – Lei Geral de Proteção de Dados Pessoais (LGPD).
                <br />
                Ao acessar o site, o Titular consente com o presente termo e
                concorda que a Controladora, tome decisões referentes a
                manipulação de seus dados pessoais, bem como realize o
                tratamento de seus dados pessoais, envolvendo operações como as
                que se referem a coleta, produção, recepção, classificação,
                utilização, acesso, reprodução, transmissão, distribuição,
                processamento, arquivamento, armazenamento, eliminação,
                avaliação ou controle da informação, modificação, comunicação,
                transferência, difusão ou extração.
                <br />
                Dados Pessoais
                <br />
                O Controlador fica autorizado a tomar decisões referentes ao
                tratamento de todos os dados pessoais do Titular solicitados nos
                formulários contidos nesse local, além de informações contidas
                nos cookies, que têm como objetivo rastrear ações,
                comportamentos e características diversas de navegação para
                melhor experiência de uso.
                <br />
                Finalidades do Tratamento dos Dados
                <br />
                O tratamento dos dados pessoais listados neste termo tem as
                seguintes finalidades:
                <br />
                Possibilitar que o Controlador identifique e entre em contato
                com o Titular para fins de relacionamento comercial.
                Possibilitar que o Controlador envie ou forneça ao Titular seus
                produtos e serviços condizentes com o formulário preenchido.
                Compartilhamento de Dados
                <br />
                O Controlador fica autorizado a compartilhar os dados pessoais
                do Titular com outros agentes de tratamento de dados, caso seja
                necessário para as finalidades listadas neste termo, observados
                os princípios e as garantias estabelecidas pela Lei nº 13.709.
                Segurança dos Dados
                <br />
                O Controlador fará os melhores esforços para a manutenção de
                medidas de segurança, técnicas e administrativas aptas a
                proteger os dados pessoais de acessos e de situações acidentais
                ou ilícitas de destruição, perda, alteração, comunicação ou
                qualquer forma de tratamento inadequado ou ilícito.
                <br />
                Em conformidade ao art. 48 da Lei nº 13.709, o Controlador
                comunicará ao Titular e à Autoridade Nacional de Proteção de
                Dados (ANPD) a ocorrência de incidente de segurança que possa
                acarretar risco ou dano relevante ao Titular.
                <br />
                Término do Tratamento dos Dados
                <br />
                O Controlador poderá manter e tratar os dados pessoais do
                Titular durante todo o período em que os mesmos forem
                pertinentes ao alcance das finalidades listadas neste termo.
                Dados pessoais anonimizados, sem possibilidade de associação ao
                indivíduo, poderão ser mantidos por período indefinido.
                <br />
                O Titular poderá solicitar via e-mail ou correspondência ao
                Controlador, a qualquer momento, que sejam eliminados os dados
                pessoais não anonimizados do Titular. O Titular fica ciente de
                que poderá ser inviável ao Controlador continuar o fornecimento
                de produtos ou serviços ao Titular a partir da eliminação dos
                dados pessoais.
                <br />
                Direitos do Titular
                <br />
                O Titular tem direito a obter do Controlador, em relação aos
                dados por ele tratados, a qualquer momento e mediante
                requisição: I - confirmação da existência de tratamento; II -
                acesso aos dados; III - correção de dados incompletos, inexatos
                ou desatualizados; IV - anonimização, bloqueio ou eliminação de
                dados desnecessários, excessivos ou tratados em desconformidade
                com o disposto na Lei nº 13.709; V - portabilidade dos dados a
                outro fornecedor de serviço ou produto, mediante requisição
                expressa, de acordo com a regulamentação da autoridade nacional,
                observados os segredos comercial e industrial; VI - eliminação
                dos dados pessoais tratados com o consentimento do titular,
                exceto nas hipóteses previstas no art. 16 da Lei nº 13.709; VII
                - informação das entidades públicas e privadas com as quais o
                controlador realizou uso compartilhado de dados; VIII -
                informação sobr/e a possibilidade de não fornecer consentimento
                e sobr/e as consequências da negativa; IX - revogação do
                consentimento, nos termos do § 5º do art. 8º da Lei nº 13.709.
                <br />
                Direito de Revogação do Consentimento Este consentimento poderá
                ser revogado pelo Titular, a qualquer momento, mediante
                solicitação via e-mail ou correspondência ao Controlador.
              </p>
            </S.PoliticaDePrivacidade>
          </Modal>
        </div>
        <S.Ok onClick={handleAcceptCookies}>
          Ok
        </S.Ok>
      </S.Container>
    );
  } else {
    return <></>;
  }
};

export default CookieButton;
