import { BsCardImage, BsGraphUp } from 'react-icons/bs'
import * as S from './styles'
import { Cores } from '../../shared/utils/styles'
import { AiOutlineHome, AiOutlineMail, AiOutlineSetting, AiOutlineUsergroupAdd, AiOutlineWhatsApp, AiOutlineYoutube } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

export const AsideAdmin = () => {
    const navigate = useNavigate()
    return (
        <S.Container>
            <div onClick={() => {
                navigate('/admin/home')
            }}>
                <AiOutlineHome size={20} color={Cores.BRANCO} />
                <h4>Home</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/visitas')
            }}>
                <BsGraphUp size={20} color={Cores.BRANCO} />
                <h4>Visitas</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/banner')
            }}>
                <BsCardImage size={20} color={Cores.BRANCO} />
                <h4>Banner</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/funcionarios')
            }}>
                <AiOutlineUsergroupAdd size={20} color={Cores.BRANCO} />
                <h4>Funcionarios</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/video')
            }}>
                <AiOutlineYoutube size={20} color={Cores.BRANCO} />
                <h4>Video</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/configuracoes')
            }}>
                <AiOutlineSetting size={20} color={Cores.BRANCO} />
                <h4>Configurações</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/whatsapp')
            }}>
                <AiOutlineWhatsApp size={20} color={Cores.BRANCO} />
                <h4>WhatsApp</h4>
            </div>
            <div onClick={() => {
                navigate('/admin/emails')
            }}>
                <AiOutlineMail size={20} color={Cores.BRANCO} />
                <h4>Emails</h4>
            </div>
        </S.Container>
    )
}