import { Funcionario } from "../../../shared/types";
import { Cores } from "../../../shared/utils/styles";
import * as S from "./styles";

type PropsCardFuncionario = {
  props: Funcionario
}

export const CardFuncionarios = ({props} : PropsCardFuncionario) => {

  return (
    <S.Conatiner>
      <div className="container-img">
        <img src={`${process.env.REACT_APP_API_FOTOS_VIDEOS}/fotos/${props?.arquivo}`} alt="" />
      </div>
        <h6 className="desc">{props?.descricao}</h6>
      <div className="nome">
        <h5>{props?.nome}</h5>
        <h6 style={{color: Cores.CINZA_500}}>{props?.setor}</h6>
      </div>
    </S.Conatiner>
  );
};
