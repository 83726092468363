import styled from "styled-components";
import { Cores } from "../../shared/utils/styles";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 66px 10px;
`

export const ContainerMobile = styled.div`

    margin-top: 30px;

    >button {
        position: fixed;
        left: 0;
        bottom: 40px;
        background-color: ${Cores.CINZA_800};
        outline: none;
        border: none;
        width: 100%;
        height: 50px;
        color: ${Cores.BRANCO};
        font-family: Montserrat;
        font-size: larger;
    }
`