import { AiOutlineWhatsApp } from 'react-icons/ai'
import { AsideAdmin } from '../../../components/AsideAdmin'
import { HeaderAdmin } from '../../../components/HeaderAdmin'
import * as S from './styles'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../App'
import { getWhats } from '../../../shared/redux/slices/litoralAdmin'
import { getWhats as getWhatsSelector } from '../../../shared/redux/selectors'
import { Select, Table } from 'antd'
import { Whats } from '../../../shared/types'

export const WhatsApp = () => {
    const dispatch = useDispatch<AppDispatch>()
    const whats = useSelector(getWhatsSelector)
    const [list, setList] = useState<any>()
    const [listFilter, setListFilter] = useState<any>()
    const anos = [];
      const anoAtual = new Date().getFullYear();
    
      for (let ano = anoAtual; ano >= 2003; ano--) {
        anos.push(ano);
      }
      const AnosData = anos?.map((x, i) => {
        return {
            key: i,
            label: x,
            value: x
        }
      })

    useEffect(()=>{
        dispatch(getWhats())
    },[])

    useEffect(()=> {
        const result = whats?.map((x, i) => {
            const [dateString, timeString] = x.data_hora?.split('T');

            const retorno = {
                ip: x.ip,
                data: dateString,
                hora: timeString,
                key: i
            }
            return retorno
        })
        setListFilter(result)
        setList(result)
    },[whats])
      
      const columns = [
        {
          title: 'IP',
          dataIndex: 'ip',
          key: 'ip',
        },
        {
          title: 'Data',
          dataIndex: 'data',
          key: 'data',
        },
        {
          title: 'Hora',
          dataIndex: 'hora',
          key: 'hora',
        },
      ];

      const handleChangeMes = (value: string) => {
        const filter = list.map((x: Whats) => {
            const date = x.data_hora.split('/')[1]
            if(date === value) {
                return x
            }
        })
        setListFilter(filter)
      };
      const handleChangeAno = (value: string) => {
        const filter = list.map((x: Whats) => {
            const date = x.data_hora.split('/')[2]
            if(date === value.toString()) {
                return x
            }
        })
        setListFilter(filter)
      };

      

    return (
        <S.Container>
            <AsideAdmin />
            <HeaderAdmin />
            <div className='title'>
                <AiOutlineWhatsApp size={40} />
                <h1>WhatsApp</h1>
            </div>
            <h2>Total de registros no mês de Abril no ano de 2023 : {list?.length}</h2>
            <h4>Filtrar registros pelo mês e ano:</h4>
            <div style={{display: "flex", flexDirection: "row", gap: 20}}>
                <Select
                    defaultValue="Selecione o mês"
                    style={{ width: 300 }}
                    onChange={handleChangeMes}
                    options={[
                        { value: '00', label: 'Selecione o mês' },
                        { value: '01', label: 'Janeiro' },
                        { value: '02', label: 'Fevereiro' },
                        { value: '03', label: 'Março' },
                        { value: '04', label: 'Abril' },
                        { value: '05', label: 'Maio' },
                        { value: '06', label: 'Junho' },
                        { value: '07', label: 'Julho' },
                        { value: '08', label: 'Agosto' },
                        { value: '09', label: 'Setembro' },
                        { value: '10', label: 'Outubro' },
                        { value: '11', label: 'Novembro' },
                        { value: '12', label: 'Dezembro' },
                    ]}
                />
                <Select
                    defaultValue="Selecione o ano"
                    style={{ width: 300 }}
                    onChange={handleChangeAno}
                    options={AnosData}
                />
            </div>
            <Table dataSource={listFilter} columns={columns} />
        </S.Container>
    )
}