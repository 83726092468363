import styled from "styled-components";
import { Cores } from "../../shared/utils/styles";

export const Container = styled.div`
    position: fixed;
    bottom: 5px;
    right: 5px;
    z-index: 55;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    >a,div{
        text-decoration: underline;
        cursor: pointer;
        color: ${Cores.BRANCO};
        background-color: ${Cores.VERDE_400};
        display: flex;
        padding:10px;
        border-radius: 50px;
        >svg{
            font-size: 40px;
        }
        
    }
`
export const ContainerMobile = styled.div`
    left: 0;
    bottom: 0;
    z-index: 55;
    width: 100%;
    position: fixed;
    display: flex;
    flex-direction: row;

    .insta {
        background-color: ${Cores.AZUL_600};
    }
    .fb {
        background: -webkit-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -webkit-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%) !important;
    }

    >a{
        text-decoration: underline;
        cursor: pointer;
        color: ${Cores.BRANCO};
        background-color: ${Cores.VERDE_400};
        display: flex;
        padding:10px;
        height: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        :hover{
            transition:0.2s all;
            scale: 1.1;
            border-radius: 5px;
            >svg{
              transform: rotate(360deg);
                transition:0.8s all;
            }
        }
        >svg{
            font-size: 40px;
        }
        
    }
`