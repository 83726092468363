import styled from "styled-components";

export const Container = styled.div`
    min-width: 65%;
    max-width: fit-content;
    margin: 30px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 15px;

    img {
        border-radius: 0 15px 15px 0;
        height: -webkit-fill-available;
        object-fit: cover;
    }
    h2 {
        font-weight: 700;
        color: white;
        font-size: 37px;
    }
    .form {
        border-radius: 15px 0 0 15px;
        padding: 50px;
        background-color: #141414;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    .ant-form-item {
        margin-bottom: 15px;
    }
    .botoes {
        margin-top: 10px;
    }
    .ant-input {
        border: 1px solid #393737;
        background-color: transparent;
        color: white;
        font-family: Montserrat;
        font-size: 14px;
        padding: 8px;
        ::placeholder {
            color: #828282;
        }
    }
    i {
        color: #CE8C4B;
    }
    .ant-select-selector {
        border: 1px solid #393737 !important;
        background-color: black;
        color: white;
        font-family: Montserrat;
        font-size: 15px;
        height: 40px !important;
        display: flex;  
        align-items: center;
        background-color: transparent !important;
        padding: 8px !important;
        ::placeholder {
            color: #828282;
        }
    }
    .titulo-form {
        width: 100%;
        display: flex;
        float: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    }
    .yellow {
        color: #CE8C4B;
    }

    .ant-row {
        width: 100%;
    }
    .ant-select {
        width: 100% !important;
    }
    .ant-form-item-margin-offset {
        display: none;
    }
    #avaliacao_celular {
        width: 100%;
        border-radius: 5px;
        outline: none;
        border: none;
        height: 42px;
        background-color: transparent;
        color: white;
        border: 1px solid #393737;
        color: white;
        font-size: 15px;
        padding: 8px 16px;
        font-family: Montserrat;
        ::placeholder {
            color: #7B7B7B;
            padding: 0 9px;
            font-size: 15px;
        }
    }
    div {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 10px;
    }
    button{
            height: 40px;
            width: 100%;
            margin: auto;
            font-weight: bold;
            transition: 0.4s all;
            cursor: pointer;
            background: linear-gradient(269.62deg, #CE8C4B 47.28%, #F8E68F 100%);
            border-radius: 20px;
            color: white;
            font-family: Montserrat;
            border: none;
            outline: none;
            :hover{
                opacity: 0.7;
            }    
    }
    .limpar {
        background: #141414;
        border: 1px solid #393737;
        border-radius: 20px;
        color: #7B7B7B;
        font-weight: 400;
    }
    @media (max-width: 991px) {
        max-width: 100%;
        padding: 0;
        margin: 0 15px 15px;
        grid-template-columns: 1fr;
        margin-top: 30px;
        margin-bottom: 40px;

        h2 {
            font-size: 25px !important;
        }
        .titulo-form {
            justify-content: start;
        }
        .form {
            padding: 25px 15px;
            border-radius: 15px;
        }
    }
`
