import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    gap: 10px;
    justify-content: space-between;
    .text {
        display: flex;
        flex-direction: column;
    }
    @media (max-width: 991px) {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
`
export const CardsInfos = styled.a`
    display: flex;
    flex-direction: column;
    @media (max-width: 1200px) {
        margin: 10px 0px;
    }
`
export const ImgsCardsInfos = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-family: Montserrat;
    font-size: 11px;

    img {
        width: 25px;
    }
`