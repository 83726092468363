import styled from "styled-components";

export const Container = styled.div`
    padding: 80px 20px 20px 100px;
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }
    h2 {
        font-weight: 600;
        font-size: large;
    }
    h4{
        font-weight: 400;
    }
`