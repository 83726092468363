import { Reducer, combineReducers } from "redux";
import { RootState } from "../../types";

import litoralReducer from "./litoralEstoque";
import litoralAdminReducer from "./litoralAdmin";
import litoralLojaReducer from "./litoralLoja";

const rootReducer: Reducer<RootState> = combineReducers<RootState>({
  litoralState: litoralReducer,
  litoralAdminState: litoralAdminReducer,
  litoralLojaState: litoralLojaReducer,
});

export default rootReducer;
