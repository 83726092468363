import { BsCardImage, BsList, BsPencilSquare, BsTrash3 } from 'react-icons/bs'
import { AsideAdmin } from '../../../components/AsideAdmin'
import { HeaderAdmin } from '../../../components/HeaderAdmin'
import * as S from './styles'
import { Button, Checkbox, Col, Dropdown, Input, Menu, Modal, Row } from 'antd'
import { useEffect, useState } from 'react'
import { getVideo } from '../../../shared/redux/slices/litoralAdmin'
import { getVideo as getVideoSelector } from '../../../shared/redux/selectors/'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../App'
import { Cores } from '../../../shared/utils/styles'
import { deleteVideo, editVideo, postVideo } from '../../../service/apiLitoral'
import { toast } from 'react-toastify'

export const Video = () => {
    // TODO Limpar campos dps de salvar sem reload
    const DateFunc = (props: string) => {
        const date = new Date(props)
        const day = date.getDate().toString().padStart(2, '0'); // obtém o dia com zero à esquerda se necessário
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // obtém o mês com zero à esquerda se necessário (lembre-se que os meses em JavaScript são baseados em zero, então adicione 1)
        const year = date.getFullYear(); // obtém o ano com quatro dígitos

        return `${day}/${month}/${year}`
    }
    const dispatch = useDispatch<AppDispatch>()
    const video = useSelector(getVideoSelector)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalEditar, setIsModalEditarOpen] = useState(false);
    const [atualiza, setAtualiza] = useState(false);
    const [situacao, setSituacao] = useState(true);
    const [bannerEditObj, setBannerEditObj] = useState<any>();
    const [file, setFile] = useState<any>();
    const [titulo, setTitulo] = useState<string>('');
    const [dateInicio, setDateInicio] = useState<string>('');
    const [dateFim, setDateFim] = useState<string>('');
    const [videoUrl, setVideoUrl] = useState('');
    const [mobile, setMobile] = useState<boolean>(false);

    const menuItems: any = [
        {
          key: 'editar',
          label: (
              <span style={{display: "flex", alignItems: "center", gap: 10}}>
                <BsPencilSquare size={20} />
                <b>Editar</b>
              </span>
          ),
        },
        {
          key: 'deletar',
          label: (
            <span style={{display: "flex", alignItems: "center", gap: 10}}>
              <BsTrash3 size={20} />
              <b>Deletar</b>
            </span>
        ),
          danger: true,
        },
      ];

    useEffect(() => {
        dispatch(getVideo())
    },[atualiza])
    useEffect(() => {
        setSituacao(bannerEditObj?.situacao)
    },[bannerEditObj])

    const showModal = () => {
        setIsModalOpen(true);
      };
    
      const onFinish = async () => {
        if(!isModalEditar) {
            if(!file || !titulo || !dateInicio || !dateFim) {
                toast.error("Preencha todos os campos!")
                return
            }
        }

        if(isModalEditar) {
            const data = {
                titulo: titulo ? titulo : bannerEditObj.titulo,
                url: '/',
                situacao: situacao === false ? "false" : "true",
                data_inicio: dateInicio ? dateInicio : bannerEditObj.data_inicio,
                data_fim: dateFim ? dateFim : bannerEditObj.data_fim,
                posicao: video ? (video.length + 1) : 0,
                isMobile: bannerEditObj.isMobile === 1 ? "true" : "false",
                arquivo: bannerEditObj?.arquivo,
                id: bannerEditObj.id,
            }
            setIsModalEditarOpen(false);
            await editVideo({
                data: data
            });
            toast.success("Video alterado com sucesso!");
            setFile(null);
            window.location.reload()
            setAtualiza(!atualiza);
        } else {
            const formData = new FormData();
            formData.append('arquivo', file);
            formData.append('titulo', titulo);
            formData.append('url', "/");
            formData.append('situacao', situacao === false ? 'false' : 'true');
            formData.append('data_inicio',  dateInicio);
            formData.append('data_fim',  dateFim);
            formData.append('isMobile',  mobile ? "true" : "false");
            formData.append('posicao',  video ? (video.length + 1).toString() : '0');

            setIsModalOpen(false);
            const result = await postVideo(formData)
            if(result === undefined) {
                return
            }
            const loadingToastId = toast.loading("Enviado vídeo...")
            toast.dismiss(loadingToastId);
            setFile(null);
            toast.success("Video cadastrado com sucesso!");
            window.location.reload()
            setAtualiza(!atualiza);
        }
      };

      const setSituacaoButton = () => {
        setSituacao(!situacao)
      };

      const setImageInput = (value: any) => {
        const file = value.target.files[0];
        setFile(file);
        const url = URL.createObjectURL(file);
        setVideoUrl(url);
      };

    return (
        <S.Container>
             <Modal footer={null} width={1000} title={isModalEditar ? "Editar video" : "Cadastrar video"} open={isModalOpen || isModalEditar} onCancel={() => {setIsModalOpen(false); setIsModalEditarOpen(false); setBannerEditObj(null); setFile(null); setSituacao(true)}}>
                <p>Tamanho ideal para vídeo(desktop): <b>1920px de largura por 1080px de altura - Full HD.</b> Tamanho recomendado: 10MB</p>
                <p>Tamanho ideal para vídeo(mobile): <b>400px de largura por 650px de altura - Full HD.</b> Tamanho recomendado: 10MB</p>
                <div>
                    <div key={isModalEditar ? bannerEditObj.titulo : null} style={{display: 'flex', flexDirection: "row", gap: 10, justifyContent: "space-around", marginTop: 20}}>
                        <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                            <div>
                                <h5>Título:</h5>
                                <Input key={isModalEditar ? bannerEditObj.titulo : null} onChange={(event) => {setTitulo(event.target.value)}} required defaultValue={isModalEditar ? bannerEditObj.titulo : null} />
                            </div>

                        <div style={{display: "flex", flexDirection: "row", gap:10 , justifyContent: "space-between"}}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <h5>Data início</h5>
                                <Input required key={isModalEditar ? bannerEditObj.data_incial : null} type="date" onChange={(event) => setDateInicio(event.target.value)} defaultValue={bannerEditObj?.data_inicio?.substr(0, 10)} />
                            </div>
                            <div style={{display: "flex", flexDirection: "column"}}>
                            <h5>Data final</h5>
                                <Input required key={isModalEditar ? bannerEditObj.data_final : null} type="date" onChange={(event) => setDateFim(event.target.value)} defaultValue={bannerEditObj?.data_fim?.substr(0, 10)} />
                                </div>
                                <div style={{display: "flex", flexDirection: "column"}}>
                            <h5>Situação</h5>
                                <Button type="primary" danger={situacao?.toString() === "false" ? true : false} onClick={setSituacaoButton}>{situacao?.toString() === "false" ? "Inativo" : "Ativo"}</Button>
                                </div>
                        </div>
                        <div>
                            {isModalEditar ? null : (
                                <>
                                    <h5>Link do vídeo no Youtube</h5>
                                    <div style={{display: 'flex', flexDirection: "row", gap: "15px", justifyContent: "center", alignItems: "center"}}>
                                        <Input required onChange={setImageInput} type='file' />
                                        <h5>Mobile</h5>
                                        <Checkbox id="mobile" onChange={(event) => {setMobile(event.target.checked)}} />
                                    </div>
                                </>
                            )}
                        </div>
                        <Button style={{backgroundColor: Cores.VERDE_600}} type="primary" onClick={onFinish}>
                            Salvar
                        </Button>
                        </div>
                        {videoUrl || bannerEditObj?.arquivo ? (<video autoPlay loop muted style={{maxWidth: 400}} src={videoUrl ? videoUrl : `${process.env.REACT_APP_API_FOTOS_VIDEOS}/videos/${process.env.REACT_APP_COD_LOJA}/${bannerEditObj?.arquivo}`} />): (
                            <p>Seu vídeo aparecerá aqui</p>
                        )}
                    </div>
                </div>
            </Modal>
            <AsideAdmin />
            <HeaderAdmin />
            <div className='title'>
                <span>
                    <BsCardImage size={40} />
                    <h1>Video</h1>
                </span>
                <Button onClick={showModal} style={{maxWidth: 180, backgroundColor: Cores.VERDE_600}} type="primary">Cadastrar novo vídeo</Button>
            </div>
            <h2>Videos cadastrados</h2>
            <div>
                <Row className='title-table'>
                    <Col span={5}>
                        <h4>Foto</h4>
                    </Col>
                    <Col span={7}>
                        <h4>Nome</h4>
                    </Col>
                    <Col span={4}>
                        <h4>Data Início</h4>
                    </Col>
                    <Col span={5}>
                        <h4>Data Final</h4>
                    </Col>
                    <Col span={1}>
                        <h4>Ações</h4>
                    </Col>
                </Row>
                {video?.map((x) => (
                <Row className='title-table'>
                    <Col span={5}>
                    <video src={`${process.env.REACT_APP_API_FOTOS_VIDEOS}/videos/${process.env.REACT_APP_COD_LOJA}/${x.arquivo}`} />
                    </Col>
                    <Col span={7}>
                        <h5>{x.titulo}</h5>
                    </Col>
                    <Col span={4}>
                        <h5>{DateFunc(x.data_inicio)}</h5>
                    </Col>
                    <Col span={5}>
                        <h5>{DateFunc(x.data_fim)}</h5>
                    </Col>
                    <Col span={1}>
                    <Dropdown placement="bottomLeft"  overlay={
                        <Menu>
                            {menuItems.map((item: any) => (
                            <Menu.Item onClick={async () => {
                                const funcEdit = video.find(m => m.id === x.id)
                                if(item?.danger) {
                                    await deleteVideo(x.id.toString())
                                    dispatch(getVideo())
                                } else {
                                    setIsModalEditarOpen(true);
                                    setBannerEditObj(funcEdit)
                                }
                            }} key={item?.key} danger={item?.danger}>
                                {item.icon} {item.label}
                            </Menu.Item>
                            ))}
                        </Menu>
                        }>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <BsList size={25} color={Cores.PRETO} />
                        </a>
                        </Dropdown>
                    </Col>
                </Row>
                ))}
            </div>
        </S.Container>
    )
}