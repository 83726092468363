import styled from "styled-components";
import { Cores } from "../../../shared/utils/styles";

export const Container = styled.div`
    margin: auto;
    color: ${Cores.BRANCO};
    font-size: 25px;
    align-items: center;
    justify-content: center;
    @media (max-width: 991px) {
       margin: 60px 0px;
    }
 `
export const DivFormMapa = styled.div`
   display: grid;
   width: 100%;
   grid-template-columns: 2fr 1.5fr;
   min-width: 65%;
   max-width: fit-content;
   margin: 30px 0px;
   margin: auto;
   border-radius: 15px !important;
   background-color: #141414;
   @media (max-width: 991px) {
      grid-template-columns: 1fr;
      padding: 0;
      margin: 0 15px 15px;
      gap: 15px;
    }
`