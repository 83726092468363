import { Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useSelector } from 'react-redux';
import { postEmailContato } from '../../../../service/apiLitoral';
import { getLoja } from '../../../../shared/redux/selectors';
import { EnvioEmailContato as EnvioEmailContatoDictionary } from '../../../../shared/utils/dictionary';
import InputMask from 'react-input-mask';
import * as S from './styles'
import { useState } from 'react';
import { toast } from 'react-toastify';
import { CardsContato } from '../CardsContato';

type FormContatoType = {
    [EnvioEmailContatoDictionary.CIDADE]: string;
    [EnvioEmailContatoDictionary.EMAIL]: string;
    [EnvioEmailContatoDictionary.MENSAGEM]: string;
    [EnvioEmailContatoDictionary.NOME]: string;
    [EnvioEmailContatoDictionary.TELEFONE]: string;
}
export const FormContato = () => {
    const [telefone, setTelefone] = useState('');
    const loja = useSelector(getLoja)
    const [form] = Form.useForm();
    
        const onFinish = (values: FormContatoType) => {
            const data = {
                [EnvioEmailContatoDictionary.CIDADE]: values.cidade,
                [EnvioEmailContatoDictionary.EMAIL]: values.email,
                [EnvioEmailContatoDictionary.EMAIL_LOJA]: loja.emails ? loja.emails[1].email : "mcveiculosmkt@gmail.com",
                [EnvioEmailContatoDictionary.MENSAGEM]: values.mensagem,
                [EnvioEmailContatoDictionary.NOME]: values.nome,
                [EnvioEmailContatoDictionary.TELEFONE]: values.telefone,
            }
            postEmailContato(data)
            toast.success("Email enviado com sucesso!")
            form.resetFields();
        };
      
      const onFinishFailed = () => {
        toast.error("Preencha todos os campos!")
      };

    return (
        <S.Container>
            <div className='titulo-form'>
            <h2>Entre em</h2>
            <h2 className='yellow'>Contato</h2>
            </div>
            <Form
                form={form}
                name="contato"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                name="nome"
                rules={[{ required: true, message: 'Insira seu nome!' }]}
                >
                    <Input placeholder='Nome' />
                </Form.Item>
                <Form.Item
                name="telefone"
                rules={[{ required: true, message: 'Insira seu telefone!' }]}
                >
                    <InputMask
                        mask="(99) 99999-9999"
                        placeholder='Telefone'
                        value={telefone}
                        onChange={(e) => setTelefone(e.target.value)}
                        />
                </Form.Item>
                <Form.Item
                name="email"
                rules={[{ required: true, message: 'Insira seu email!' }]}
                >
                    <Input placeholder='Email' />
                </Form.Item>
                <Form.Item
                name="cidade"
                rules={[{ required: true, message: 'Insira sua cidade!' }]}
                >
                    <Input placeholder='Cidade' />
                </Form.Item>
                <Form.Item
                name="mensagem"
                >
                    <TextArea  style={{ resize: 'none' }} rows={4} placeholder="Sua mensagem" />
                </Form.Item>
                <div>
                    <button type='submit'>ENVIAR E-MAIL</button>
                </div>
                <CardsContato />
        </Form>
        </S.Container>
    )
}