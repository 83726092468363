import CookieButton from '../../components/Cookies';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../App';
import { WhatsappFlutuante } from '../../components/WhatsappFlutuante';
import { getEstoque } from '../../shared/redux/slices/litoralEstoque';
import { Destaque } from './Destaque';
import Header from './Header';
import { InformacoesLoja } from '../../components/InformacoesLoja';
import * as S from './styles'
import { getInfoLoja } from '../../shared/redux/slices/litoralAdmin';
import { Instagram } from '../../components/Instagram';
import Metatags from '../../components/Metatags';

const Home: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()

    useEffect(()=>{
        dispatch(getEstoque())
        dispatch(getInfoLoja())
    },[dispatch])
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    return (
        <>
            <S.Container>
                <Metatags title="MC Veículos" description="Mc Veículos - O lugar certo para sair de carro novo!" url={window.location.href} img={`${process.env.PUBLIC_URL}/LogoLoja.ico`}/>
                <Header />
                <Destaque />
                <InformacoesLoja depoimentos={true}/>
                <Instagram/> 
                <WhatsappFlutuante/>
                <CookieButton/>
            </S.Container>
        </>
    )
}

export default Home;