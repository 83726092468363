import * as S from "./styles";
import { isMobile } from "react-device-detect";

export const Instagram = () => {

  return (
    <>
      <S.Container>
        <div style={{width: "100%"}} className='max-w-[1500px] w-full overflow-hidden'>
          <iframe src='https://d3d33de2873a491d906296e4bd58dbfa.elf.site' width='100%' height={isMobile ? '650' : '750'} frameBorder='0'></iframe>
        </div>
      </S.Container>
    </>
  );
};
