import * as S from './styles'
import { Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import { postEmailPosVenda } from '../../../service/apiLitoral';
import { EnvioEmailPosVenda as EnvioEmailPosVendaDictionary} from '../../../shared/utils/dictionary';
import { useState } from 'react';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getLoja } from '../../../shared/redux/selectors';
import imgBack from '../../../assets/images/mcBackgroundForm.png'
import { isMobile } from 'react-device-detect';

type FormContatoType = {
    [EnvioEmailPosVendaDictionary.CLIENTE]: string;
    [EnvioEmailPosVendaDictionary.CONTATO]: string;
    [EnvioEmailPosVendaDictionary.DATA_COMPRA]: string;
    [EnvioEmailPosVendaDictionary.EMAIL]: string;
    [EnvioEmailPosVendaDictionary.PLACA]: string;
    [EnvioEmailPosVendaDictionary.PROBLEMAS]: string;
    [EnvioEmailPosVendaDictionary.VEICULO]: string;
    [EnvioEmailPosVendaDictionary.VENDEDOR]: string;
}

export const FormPosvenda = () => {
    const loja = useSelector(getLoja)
    const [form] = Form.useForm();
    const [telefone, setTelefone] = useState('');
    const [data, setData] = useState('');

    const onFinish = (values: FormContatoType) => {
        const data = {
            [EnvioEmailPosVendaDictionary.CLIENTE]: values.cliente,
            [EnvioEmailPosVendaDictionary.CONTATO]: values.contato,
            [EnvioEmailPosVendaDictionary.DATA_COMPRA]: values.dataCompra,
            [EnvioEmailPosVendaDictionary.EMAIL]: values.email,
            [EnvioEmailPosVendaDictionary.PLACA]: values.placa,
            [EnvioEmailPosVendaDictionary.PROBLEMAS]: values.problemas,
            [EnvioEmailPosVendaDictionary.VEICULO]: values.veiculo,
            [EnvioEmailPosVendaDictionary.VENDEDOR]: values.vendedor,
            [EnvioEmailPosVendaDictionary.EMAIL_LOJA]: loja.emails ? loja.emails[1].email : "mcveiculosmkt@gmail.com",
        }
        postEmailPosVenda(data)
        toast.success("Email enviado com sucesso!")
        form.resetFields();
      };
      
      const onFinishFailed = () => {
        toast.error("Preencha todos os campos!")
      };

    return (
        <S.Container>
            <Form
                form={form}
                name="posVenda"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className='form'
            >
                <div className='titulo-form'>
                    <h2>Avaliar nosso</h2>
                    <h2 className='yellow'>Pós-venda</h2>
                </div>
                <Form.Item
                name="vendedor"
                rules={[{ required: true, message: 'Insira o nome do vendedor!' }]}
                >
                    <Input placeholder='Vendedor' />
                </Form.Item>
                <Form.Item
                name="veiculo"
                rules={[{ required: true, message: 'Insira o veiculo!' }]}
                >
                    <Input placeholder='Veiculo' />
                </Form.Item>
                <Form.Item
                name="placa"
                rules={[{ required: true, message: 'Insira a placa do veículo!' }]}
                >
                    <Input placeholder='Placa' />
                </Form.Item>
                <Form.Item
                name="dataCompra"
                rules={[{ required: true, message: 'Insira a data da compra!' }]}
                >
                    <InputMask
                        mask="99/99/9999"
                        placeholder='Data compra'
                        value={data}
                        onChange={(e) => setData(e.target.value)}
                        />
                </Form.Item>
                <Form.Item
                name="cliente"
                rules={[{ required: true, message: 'Insira seu nome!' }]}
                >
                    <Input placeholder='Cliente' />
                </Form.Item>
                <Form.Item
                name="contato"
                rules={[{ required: true, message: 'Insira um telefone!' }]}
                >
                    <InputMask
                        mask="(99) 99999-9999"
                        placeholder='Telefone'
                        value={telefone}
                        onChange={(e) => setTelefone(e.target.value)}
                        />
                </Form.Item>
                <Form.Item
                name="email"
                rules={[{ required: true, message: 'Insira seu email!' }]}
                >
                    <Input placeholder='Email' />
                </Form.Item>
                <Form.Item
                name="problemas"
                >
                    <TextArea  style={{ resize: 'none' }} rows={4} placeholder="Problemas relatados" />
                </Form.Item>
                    <button type='submit'>ENVIAR E-MAIL</button>
                </Form>
                {isMobile ? null : <img src={imgBack} alt="MC" />}
        </S.Container>
    )
}