import styled from "styled-components";

export const Container = styled.div`

    width: 330px;

    h2 {
        font-weight: 500;
        font-size: 25px;
        color: white;
    }
    h3 {
        color: white;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    hr {
        border: 1px solid #272727;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .ant-input-data-count {
        color: #393737;
    }
    #proposta_telefone {
        width: 100%;
        border-radius: 20px;
        height: 42px;
        outline: none;
        background-color: #141414;
        border-radius: 20px;
        border-radius: 20px;
        border: 1px solid #393737;
        font-size: 15px;
        padding: 8px 16px;
        color: white;
        ::placeholder{
            font-size: 15px;
            color: #7B7B7B;
        }
    }
    .envio {
        background: linear-gradient(90deg, #F8E68F 0%, #CE8C4B 51.04%);
        color: white;
        border-radius: 100px;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 600;
        width: 100%;
        border: none;
        outline: none;
        height: 45px;
    }
    .whats {
        background-color: #18AF50 !important;
        color: white;
        border-radius: 100px;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 500;
        width: 100%;
        border: none;
        outline: none;
        height: 45px;
    }
    .ant-input {
        background-color: #141414;
        border-radius: 20px;
        border-radius: 20px;
        border: 1px solid #393737;
        color: white;
        font-size: 15px;
        padding: 8px 16px;
        ::placeholder{
            color: #7B7B7B;
            font-size: 15px;
        }
    }
    @media (max-width: 991px) {
        width: 100%;
    }
`