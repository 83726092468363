import styled from "styled-components";

export const Container = styled.div`
    max-width: calc((100vw / 4) - 50px);
    max-height: auto;
    scroll-snap-align: start;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #5C5757;
    border-radius: 10px;
    padding: 20px;

    span {
        display: flex;
        flex-direction: row !important;
        justify-content: start;
        gap: 10px;
        padding: 0;
    }
    .usuario {
        width: 50px;
        height: 50px;
    }
    h2 {
        font-weight: 500;
        font-size: 15px;
    }
    h3 {
        font-size: 14px;
        font-weight: 400;
        max-width: 100%;
    }
    @media (max-width: 991px) {
        max-width: 100%;
    }
`