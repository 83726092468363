import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getEmails as getEmailsService, getLoja as getLojaService, getEnderecos as getEnderecosService, getTelefones as getTelefonesService, buscarSlide } from "../../../service/apiLitoral";
import { LitoralLojaState, Telefones, Emails, Enderecos, Loja, SlideBody, Retorno } from "../../types";
import { Loja as LojaDictionary, Telefones as TelefoneDictionary, Enderecos as EnderecosDictionary, Emails as EmailsDictionary } from "../../utils/dictionary";

const initialState: LitoralLojaState = {
  banner: [],
  loja: {
    [LojaDictionary.CODIGO_LOJA]: "",
    [LojaDictionary.NOME_EMPRESA]: "",
    [LojaDictionary.TELEFONES]: [{
      [TelefoneDictionary.TELEFONE]: "",
      [TelefoneDictionary.OBS]: "",
      [TelefoneDictionary.WHATSAPP]: "",
    }],
    [LojaDictionary.ENDERECOS]: [{
      [EnderecosDictionary.BAIRRO]: "",
      [EnderecosDictionary.CEP]: "",
      [EnderecosDictionary.CIDADE]: "",
      [EnderecosDictionary.LOGRADOURO]: "",
      [EnderecosDictionary.UF]: "",
    }],
    [LojaDictionary.EMAILS]: [{
      [EmailsDictionary.EMAIL]: "",
      [EmailsDictionary.NOME]: "",
      [EmailsDictionary.OBS]: "",
      [EmailsDictionary.VISIVEL_SITE]: "",
      [EmailsDictionary.RECEBE_PROPOSTA]: "",
      [EmailsDictionary.RECEBE_REPASSE]: "",
      [EmailsDictionary.CRITERIO]: "",
      [EmailsDictionary.TIPO]: "",
    }],
  },
};

const litoralLojaSlice = createSlice({
  name: "litoralLojaSlice",
  initialState,
  reducers: {
    resetState: (state:LitoralLojaState) => {
      state = initialState
    },
  },
  extraReducers: (builder) => {
      builder.addCase(
        getSlide.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.banner = action.payload;
        }
      );
    builder.addCase(
        getTelefones.fulfilled,
        (state, action: PayloadAction<Retorno<Telefones[]>>) => {
          if(state.loja?.telefones?.[0].telefone === "") {
            state.loja.telefones = action.payload.retorno
          }
        }
      );
      builder.addCase(
        getEmails.fulfilled,
        (state, action: PayloadAction<Retorno<Emails[]>>) => {
          if(state.loja?.emails?.[0].email === "") {
            state.loja.emails = action.payload.retorno
          }
        }
      );
      builder.addCase(
        getEnderecos.fulfilled,
        (state, action: PayloadAction<Retorno<Enderecos[]>>) => {
          if(state.loja?.enderecos[0].bairro === "") {
            state.loja.enderecos = action.payload.retorno
          }
        }
      );
      builder.addCase(
        getLoja.fulfilled,
        (state, action: PayloadAction<Retorno<Loja>>) => {
          state.loja = action.payload.retorno;
        }
      );
  },
});

export const getSlide = createAsyncThunk<SlideBody, void>(
    "loja/getSlide",
    async () => {
      const response = await buscarSlide();
      return response?.data; 
    }
  );

export const getLoja = createAsyncThunk<Retorno<Loja>, void>(
    "loja/getLoja",
    async () => {
      const response = await getLojaService();
      return response?.data; 
    }
  );
  
  export const getTelefones = createAsyncThunk<Retorno<Telefones[]>, void>(
    "loja/getTelefones",
    async () => {
      const response = await getTelefonesService();
      return response?.data; 
    }
  );
  
  export const getEnderecos = createAsyncThunk<Retorno<Enderecos[]>, void>(
    "loja/getEnderecos",
    async () => {
      const response = await getEnderecosService();
      return response?.data; 
    }
  );
  
  export const getEmails = createAsyncThunk<Retorno<Emails[]>, void>(
    "loja/getEmails",
    async () => {
      const response = await getEmailsService();
      return response?.data; 
    }
  );
  

export const { resetState } = litoralLojaSlice.actions;
export default litoralLojaSlice.reducer;
