
import * as S from './styles'
import {getEstoqueFiltrado, getLoja as getLojaSelectors } from '../../../shared/redux/selectors';
import { useSelector } from 'react-redux';
import { Loja as LojaDictionary } from '../../../shared/utils/dictionary';
import CardVeiculo from '../../../components/CardVeiculo';
import { Filtro } from '../Filtro';

export const EstoqueConteudo = () => {
    const loja = useSelector(getLojaSelectors)
    const veiculos = useSelector(getEstoqueFiltrado)
    const codLoja = loja?.[LojaDictionary.CODIGO_LOJA]

    return (
        <S.Container>
            <h2>CONHEÇA NOSSO ESTOQUE COMPLETO</h2>
            <div className='hr' />
            <Filtro />
            <S.Cards>
                {veiculos.map((veiculo) => {
                    return <CardVeiculo key={veiculo.cod_veiculo} codLoja={codLoja} veiculo={veiculo} />
                })}
            </S.Cards>
        </S.Container>
    )
}