import styled from "styled-components";

export const ImageCarouselWrapper = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;

  img {
    max-width: 100%;
    cursor: pointer;
  }
  .image-main {
    border-radius: 15px;
  }
  .main {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  i {
    color: white;
    cursor: pointer;
  }
`;

export const ThumbnailsWrapper = styled.div`
  display: flex;
  justify-content: initial;
  overflow: scroll;
  overflow-x: hidden; /* esconde a barra de rolagem horizontal */
  overflow-y: hidden; /* esconde a barra de rolagem vertical */
  margin-top: 15px;
  @media (max-width: 991px) {
    margin-top: 382px;
    justify-content: initial;
    overflow: scroll;
    width: 500px;
  }
  @media (max-width: 619px) {
    margin-top: 240px;
    justify-content: initial;
    overflow: scroll;
    width: 300px;
  }
  
`;

export const Thumbnail = styled.img`
  width: 60px;
  height: auto;
  margin: 0 5px;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.5s ease;

  &:hover {
    opacity: 1;
  }
`;