import styled from "styled-components";
import { Cores } from "../../../shared/utils/styles";

export const Container = styled.div`
    background-color: ${Cores.CINZA_200};
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;

    h2 {
        font-weight: 400;
    }

    div {
        background-color: ${Cores.BRANCO};
        border-radius: 10px;
        padding: 20px 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
        min-width: 350px;
    }
    button {
        border: none;
        outline: none;
        color: white;
        padding: 10px 20px;
        border-radius: 10px;
        font-family: Montserrat;
        cursor: pointer;
    }
`