import styled from "styled-components";
import { Cores } from "../../../shared/utils/styles";

export const Container = styled.div`
    padding: 80px 20px 20px 100px;
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title-page {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }
    .select .ant-select-selector {
        border: none;
    }
    h5 {
            font-weight: 300;
            font-size: medium;
        }

    .title-table {
        border-bottom: 1px solid ${Cores.PRETO};
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 20px;
        align-items: center;
    }

    .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 20px;
        align-items: center;
        >span {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
        }
    }
    h2 {
        font-weight: 300;
    }
`