import styled from "styled-components";
import { Cores } from "../../../shared/utils/styles";

export const Container =  styled.div`
    margin: 70px auto 0;
    text-align: center;
    color: ${Cores.BRANCO};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    >h3{
        font-size: 17px;
        font-weight: 600;
    }
    .hr {
        height: 1px;
        background: linear-gradient(to right, #CD8A49 0%, #F8E58F 100%);
        border-radius: 5px;
        width: 140px;
    }
`