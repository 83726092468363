import { AiOutlineUsergroupAdd } from 'react-icons/ai'
import { AsideAdmin } from '../../../components/AsideAdmin'
import { HeaderAdmin } from '../../../components/HeaderAdmin'
import * as S from './styles'
import { Button, Col, Dropdown, Form, Input, Menu, Modal, Row } from 'antd'
import { Cores } from '../../../shared/utils/styles'
import { useEffect, useState } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { toast } from 'react-toastify'
import { deleteFuncionario, editFuncionario, postFuncionario } from '../../../service/apiLitoral'
import { Funcionario } from '../../../shared/types'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../App'
import { getFuncionarios } from '../../../shared/redux/slices/litoralAdmin'
import { getFuncionarios as getFuncionariosSelector } from '../../../shared/redux/selectors'
import { BsList, BsPencilSquare, BsTrash3 } from 'react-icons/bs'

export const Funcionarios = () => {
    // TODO limpar imagem
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [formEdit] = Form.useForm();
    const [isModalEditar, setIsModalEditarOpen] = useState(false);
    const [atualiza, setAtualiza] = useState(false);
    const [situacao, setSituacao] = useState(true);
    const [file, setFile] = useState<any>();
    const [funcionarioEditObj, setFuncionarioEditObj] = useState<any>();
    const dispatch = useDispatch<AppDispatch>()
    const [imgUrl, setImgUrl] = useState<any>();
    const funcionarios = useSelector(getFuncionariosSelector)

    const menuItems: any = [
        {
          key: 'editar',
          label: (
              <span style={{display: "flex", alignItems: "center", gap: 10}}>
                <BsPencilSquare size={20} />
                <b>Editar</b>
              </span>
          ),
        },
        {
          key: 'deletar',
          label: (
            <span style={{display: "flex", alignItems: "center", gap: 10}}>
              <BsTrash3 size={20} />
              <b>Deletar</b>
            </span>
        ),
          danger: true,
        },
      ];

      function forceUpdate() {
        setIsModalEditarOpen(false);
        setTimeout(() => setIsModalEditarOpen(true), 0);
      }

    const onFinish = async (data: Funcionario) => {
        if(isModalEditar) {
            const dataResult = {
                nome: data.nome,
                setor: data.setor,
                descricao: data.descricao,
                situacao: situacao,
                arquivo: file ? file : funcionarioEditObj.arquivo,
                id: funcionarioEditObj.id, 
            }
            setIsModalEditarOpen(false)
            await editFuncionario({data: dataResult})
            formEdit.resetFields();
            setFile(null);
            setAtualiza(!atualiza)
            toast.success("Funcionario atualizado com sucesso!")
            window.location.reload()
        } else {
            const formData = new FormData();
            formData.append('nome', data.nome);
            formData.append('setor', data.setor);
            formData.append('descricao', data.descricao);
            formData.append('situacao', situacao === false ? 'false' : 'true');
            formData.append('arquivo',  file ? file : funcionarioEditObj.arquivo);
            setOpen(false)
            await postFuncionario(formData)
            form.resetFields();
            setFile(null);
            setAtualiza(!atualiza)
            toast.success("Funcionario cadastrado com sucesso!")
            window.location.reload()
        }
    }
      const setImageInput = (value: any) => {
        const file = value.target.files[0];
        setFile(file);
        const url = URL.createObjectURL(file);
        setImgUrl(url);
      };

      useEffect(() => {
        dispatch(getFuncionarios())
    },[atualiza])

    useEffect(() => {
        const initial = {
            nome: funcionarioEditObj?.nome,
            descricao: funcionarioEditObj?.descricao,
            setor: funcionarioEditObj?.setor
        }
        formEdit.setFieldsValue(initial)
       }, [form, funcionarioEditObj])
    
    return (
        <S.Container>
            <Modal
                title="Editar Funcionário"
                centered
                open={isModalEditar}
                onOk={() => {setIsModalEditarOpen(false)}}
                onCancel={() => {
                    setIsModalEditarOpen(false)
                    }}
                width={1000}
                style={{maxHeight: 500}}
                footer={null}
            >
                <Form
                    layout='vertical'
                    form={formEdit}
                    name="editar-banner"
                    initialValues={{ 
                        nome: funcionarioEditObj?.nome,
                        setor:funcionarioEditObj?.setor,
                        descricao:funcionarioEditObj?.descricao,
                     }}
                    onFinish={onFinish}
                >
                    <div style={{display: 'flex', flexDirection: "row", justifyContent: "space-evenly", marginTop: 20}}>
                        <div style={{width: 300}}>
                        <Form.Item
                            label="Nome do funcionário"
                            name="nome"
                            rules={[{ required: true, message: 'Preencha o nome do funcionário' }]}
                        >
                            <Input defaultValue={funcionarioEditObj?.nome} placeholder='Nome do seu funcionário' />
                        </Form.Item>
                        <Form.Item
                            label="Setor"
                            name="setor"
                            rules={[{ required: true, message: 'Preencha o setor do funcionário' }]}
                        >
                            <Input placeholder='Em qual setor atua' />
                        </Form.Item>
                        <Form.Item
                            label="Descrição"
                            name="descricao"
                            rules={[{ required: true, message: 'Preencha a descrição do funcionário' }]}
                        >
                            <TextArea
                                showCount
                                maxLength={400}
                                style={{ height: 100, resize: 'none' }}
                                placeholder="Descrição sobre"
                                />
                        </Form.Item>
                            <Form.Item
                                label="Situação"
                                name="situacao"
                            >
                        <Button type="primary" style={{width: "100%"}} danger={situacao === false ? true : false} onClick={() => setSituacao(!situacao)}>{situacao === false ? "Inativo" : "Ativo"}</Button>
                        </Form.Item>
                        <Form.Item>
                        <Button style={{backgroundColor: Cores.VERDE_600, width: "100%"}} type="primary" htmlType="submit">
                            Salvar
                        </Button>
                    </Form.Item>
                        </div>
                        <div style={{display: 'flex' ,gap: 20, flexDirection: 'column'}}>
                        {imgUrl || funcionarioEditObj ? (<img style={{maxWidth: 400}} src={imgUrl ? imgUrl : `${process.env.REACT_APP_API_FOTOS_VIDEOS}/fotos/${funcionarioEditObj.arquivo}`} alt="" />): (
                            <p style={{backgroundColor: Cores.CINZA_100, width: '100%', height: 240, display: "flex", justifyContent: "center", alignItems: "center"}}>Sua imagem aparecerá aqui</p>
                        )}
                        </div>
                    </div>
                </Form>
            </Modal>
            <Modal
                title="Cadastrar Funcionário"
                centered
                open={open}
                onOk={() => {setOpen(false)}}
                onCancel={() => {
                    setOpen(false);
                    }}
                width={1000}
                style={{maxHeight: 500}}
                footer={null}
            >
                <Form
                    layout='vertical'
                    form={form}
                    name="cadastro-banner"
                    onFinish={onFinish}
                >
                    <div style={{display: 'flex', flexDirection: "row", justifyContent: "space-evenly", marginTop: 20}}>
                        <div style={{width: 300}}>
                        <Form.Item
                            label="Nome do funcionário"
                            name="nome"
                            rules={[{ required: true, message: 'Preencha o nome do funcionário' }]}
                        >
                            <Input placeholder='Nome do seu funcionário' />
                        </Form.Item>
                        <Form.Item
                            label="Setor"
                            name="setor"
                            rules={[{ required: true, message: 'Preencha o setor do funcionário' }]}
                        >
                            <Input placeholder='Em qual setor atua' />
                        </Form.Item>
                        <Form.Item
                            label="Descrição"
                            name="descricao"
                            rules={[{ required: true, message: 'Preencha a descrição do funcionário' }]}
                        >
                            <TextArea
                                showCount
                                maxLength={400}
                                style={{ height: 100, resize: 'none' }}
                                placeholder="Descrição sobre"
                                />
                        </Form.Item>
                            <Form.Item
                                label="Situação"
                                name="situacao"
                            >
                                <Button style={{width: "100%"}} type="primary" danger={situacao === false ? true : false} onClick={() => setSituacao(!situacao)}>{situacao === false ? "Inativo" : "Ativo"}</Button>
                            </Form.Item>
                        <Form.Item>
                        <Button style={{backgroundColor: Cores.VERDE_600, width: "100%"}} type="primary" htmlType="submit">
                            Salvar
                        </Button>
                    </Form.Item>
                        </div>
                        <div style={{display: 'flex' ,gap: 20, flexDirection: 'column'}}>
                        {imgUrl ? (<img style={{maxWidth: 400}} src={imgUrl} alt="" />): (
                            <p style={{backgroundColor: Cores.CINZA_100, width: '100%', height: 240, display: "flex", justifyContent: "center", alignItems: "center"}}>Sua imagem aparecerá aqui</p>
                        )}
                        <Form.Item
                                name="arquivo"
                                style={{width: "100%"}}
                            >
                                <Input required onChange={setImageInput} type='file' />
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Modal>
            <AsideAdmin />
            <HeaderAdmin />
            <div className='title-page'>
                <AiOutlineUsergroupAdd size={40} />
                <h1>Funcionários</h1>
            </div>
            <Button onClick={() => {setOpen(true); }} style={{maxWidth: 200, backgroundColor: Cores.VERDE_600}} type="primary">Cadastrar funcionário</Button>
            <h2>Funcionários cadastrados</h2>
            <div>
                <Row className='title-table'>
                    <Col span={5}>
                        <h4>Foto</h4>
                    </Col>
                    <Col span={7}>
                        <h4>Nome</h4>
                    </Col>
                    <Col span={7}>
                        <h4>Setor</h4>
                    </Col>
                    <Col span={1}>
                        <h4>Ações</h4>
                    </Col>
                </Row>
                {funcionarios?.map((x) => (
                <Row key={x.id} className='title-table'>
                    <Col span={5}>
                    <img style={{maxWidth: 150}} src={`${process.env.REACT_APP_API_FOTOS_VIDEOS}/fotos/${x.arquivo}`} alt={x.nome} />
                    </Col>
                    <Col span={7}>
                        <h5>{x.nome}</h5>
                    </Col>
                    <Col span={7}>
                        <h5>{x.setor}</h5>
                    </Col>
                    <Col span={1}>
                    <Dropdown placement="bottomLeft"  overlay={
                        <Menu>
                            {menuItems.map((item: any) => (
                            <Menu.Item onClick={async () => {
                                const funcEdit = funcionarios.find(m => m.id === x.id)

                                if(item?.danger) {
                                    await deleteFuncionario(x.id.toString())
                                    dispatch(getFuncionarios())
                                } else {
                                    setIsModalEditarOpen(true);
                                    setFuncionarioEditObj(funcEdit)
                                    forceUpdate()
                                }
                            }} key={item?.key} danger={item?.danger}>
                                {item.icon} {item.label}
                            </Menu.Item>
                            ))}
                        </Menu>
                        }>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <BsList size={25} color={Cores.PRETO} />
                        </a>
                        </Dropdown>
                    </Col>
                </Row>
                ))}
            </div>
        </S.Container>
    )
}