import styled from "styled-components"
import { Cores } from "../../shared/utils/styles"
export const Container = styled.div`
    position: fixed;
    bottom: 20px;
    
    z-index: 6;
    display: flex;
    padding:20px;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    background-color: rgb(255 255 255 / 72%);
    @media (max-width: 991px) {
        span {
            font-size: 12px;
        }
    }
`
export const PoliticaDePrivacidade = styled.div`
    cursor: pointer;
    >div{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        
    }
`
export const Ok = styled.div`
    cursor: pointer;
    padding:10px;
    background-color: ${Cores.CINZA_300};
    border-radius: 10px;
    color: white;
    font-weight: bold;
    transition: 0.2s all;
    :hover{
        scale: 1.1;
        opacity: 0.7;
    }
`