export const Cores = {
    PRETO: '#000000',
    BRANCO: '#ffffff',
    AMARELO: '#d4ad2e',
    VERMELHO_500: 'red',
    CINZA_100: '#f0eeee',
    CINZA_200: '#adb5bd',
    CINZA_300: '#333333',
    CINZA_400: '#c8c8c8',
    CINZA_500: '#a8a5a5',
    CINZA_600: '#757272',
    CINZA_700: '#f0dfdfa6',
    CINZA_800: '#1e2125',
    CINZA_900: '#212529',
    VERDE_400: '#00a85a',
    LARANJA_300: '#fdc662',
    VERDE_600: "#198754",
    AZUL_600: "#3b5999"
}
