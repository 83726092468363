import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;

    .hr {
        width: 98%;
        height: 1px;
        background: linear-gradient(to right, #CD8A49 0%, #F8E58F 100%);
        border-radius: 5px;
    }
    h6 {
        margin-top: -20px;
        font-weight: 500;
        font-size: 12px;
        margin-bottom: 15px;
        background: radial-gradient(50% 50% at 50% 50%, #F8E48E 0%, #CD8A4A 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    align-items: self-start;
    width: 100%;
    color: white;
    box-sizing: border-box;

    .input {
        border-bottom: 1px solid white;
        border-radius: 0;
        margin-bottom: -10px;
        color: white !important;
        ::placeholder {
            color: white;
            font-family: Montserrat;
            font-size: 11e
            px;
        }
    }
    .button {
        background: radial-gradient(100% 5191.39% at 100% 0%, #CE8C4B 32.29%, #F8E68F 100%);
        color: white;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        border: none;
        border-radius: 100px;
    }
    h2 {
        font-weight: 700;
        font-size: 20px;
        text-align: justify;
        font-variant: small-caps;
        background: radial-gradient(74.94% 74.94% at 50% 25.06%, #F8E68F 0%, #CE8C4B 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    h3 {
        font-weight: 400;
        font-size: 14px;
        text-align: justify;
    }
    h4 {
        font-weight: 400;
        font-size: 15px;
        cursor: pointer;
        font-family: Montserrat;
        cursor: pointer;
    }
    >div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: calc(100vw / 5);
        >span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 10px;
        margin-top: -10px;
    }
    }
    i {
        cursor: pointer;
    }
`
export const ContainerMobile = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    gap: 20px;
    padding: 0 15px;

    .sobre {
        display: flex;
        flex-direction: row;
        >div {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        span {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }
    .social {
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }
    .hr {
        width: 98%;
        height: 1px;
        background: linear-gradient(to right, #CD8A49 0%, #F8E58F 100%);
        border-radius: 5px;
    }
    h6 {
        margin-top: -20px;
        font-weight: 500;
        background: radial-gradient(50% 50% at 50% 50%, #F8E48E 0%, #CD8A4A 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .input {
        border-bottom: 1px solid white;
        border-radius: 0;
        margin-bottom: -10px;
        color: white !important;
        ::placeholder {
            color: white;
            font-family: Montserrat;
            font-size: 11e
            px;
        }
    }
    .button {
        background: radial-gradient(100% 5191.39% at 100% 0%, #CE8C4B 32.29%, #F8E68F 100%);
        color: white;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        border: none;
        border-radius: 100px;
    }
    h2 {
        font-weight: 600;
        font-size: 17px;
        text-align: justify;
        font-variant: small-caps;
        background: radial-gradient(74.94% 74.94% at 50% 25.06%, #F8E68F 0%, #CE8C4B 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    h3 {
        font-weight: 400;
        font-size: 12px;
        text-align: justify;
        margin-bottom: 30px;
    }
    h4 {
        font-weight: 400;
        font-size: 13px;
        cursor: pointer;
        font-family: Montserrat;
        cursor: pointer;
    }
    >div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        >span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 10px;
        margin-top: -10px;
    }
    }
    i {
        cursor: pointer;
    }
    iframe {
        width: auto;
        height: 200px;
        border: none;
        border-radius: 15px;
    }
`